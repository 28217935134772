import React from "react";
import { svg } from "../../utils/svg";

const CustSearch = ({ value, setvalue }) => {
  return (
    <div className="cust_search">
      {svg.search()}
      <input
        {...{
          value,
          onChange: ({ target: { value } }) => {
            setvalue(value);
          },
          placeholder: "Search",
        }}
      />
    </div>
  );
};

export default CustSearch;
