import React, { useEffect, useState } from "react";
import OrderTypeHeader from "../../Orders/OrderTypeHeader";
import { useNavigate, useParams } from "react-router-dom";
import ActivityTracker from "../../Orders/components/ActivityTracker/ActivityTracker";
import { svg } from "../../../../../services/svg";
import { useSelector } from "react-redux";
import {
  getCustomersByID,
  getSanityClinics,
  UpdateClinics,
  updateIntake,
} from "../../../../../services/api";
import { dateFormatter, formatPhone } from "../../../../../utils/utils";
import CustTable from "../../../../../components/Table/CustTable";
import IntakeView from "../../../../../components/Intake/IntakeView/IntakeView";
import CustDropdown from "../../../../../components/CustInput/CustDropdown";
import Results from "../../components/Results";

const CustomerView = ({ submenu }) => {
  const { customerid, page } = useParams();
  const {
    user: { token },
    products,
  } = useSelector((state) => state.appState);
  const def_nav = {
    overview: { name: "Overview" },
    carehistory: { name: "Care History" },
    healthrecord: { name: "Health Record" },
    reports: { name: "Reports" },
  };
  const navigate = useNavigate();
  const [intakeold, setintakeold] = useState({});
  const [intake, setintake] = useState({});
  const [intake_edit, setintake_edit] = useState(false);
  const [user, setuser] = useState({});
  const [subnav, setsubnav] = useState(
    page ? def_nav[page]?.name || "Overview" : "Overview"
  );
  const [clinicsList, setclinicsList] = useState([]);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");
  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const [selectedClinic, setselectedClinic] = useState("");
  const [reports_obj, setreports_obj] = useState({});
  useEffect(() => {
    getCustomersByID(
      token,
      customerid,
      (success) => {
        if (success) {
          setuser(success);
          setintake(success.customer.intake);
          setintakeold(success.customer.intake);
          setreports_obj({
            reports: [...(success?.reports || [])],
            activity: {
              show: false,
              data: [],
            },
            intake: {
              ...(success?.customer?.intake || {}),
            },
          });
        }
      },
      (err) => {
        //
      }
    );
    getSanityClinics(
      token,
      (success) => {
        setclinicsList(
          success.map((el) => ({
            key: el.id,
            value: el.name,
          }))
        );
      },
      (error) => {}
    );
  }, []);
  return (
    <div className="customer_view dashboard_page">
      {console.log(user)}
      <OrderTypeHeader
        {...{
          breadCrumbs: [
            {
              name: `${user?.customer?.user?.first_name} ${user?.customer?.user?.last_name}`,
            },
          ],
          // orderDate: "Member since 1 May 2024",

          nav_list: Object.values(def_nav).map(({ name }) => name),
          nav: subnav,
          customer: {
            phone: user?.customer?.user?.phone || "",
            email: user?.customer?.user?.email || "",
          },
          setnav: (txt) => {
            navigate(
              "../" + customerid + "/" + txt.replaceAll(" ", "").toLowerCase()
            );
            setsubnav(txt);
          },
        }}
      />
      <div className="dashboard_body">
        {{
          Overview: (
            <div className="oerview_body">
              <div className="left">
                <div className="collection_main">
                  <div className="collection details_collection">
                    <div className="header">
                      Details
                      {/* <div className="btn">Edit</div> */}
                    </div>
                    <div className="body">
                      <div className="title">CONTACT</div>
                      <div className="email">
                        {user?.customer?.user?.email}
                        {svg.clipboard}
                      </div>
                      <div className="phone">
                        {formatPhone(
                          user?.customer?.user?.phone,
                          user?.customer?.user?.prefix
                        )}
                        {svg.clipboard}
                      </div>
                      <div className="title">ADDRESS</div>
                      <div className="address">
                        {[
                          user?.customer?.address?.apt,
                          user?.customer?.address?.address,
                          user?.customer?.address?.landmark,
                          user?.customer?.address?.city,
                          user?.customer?.address?.pin_code,
                        ].join(", ")}
                      </div>
                    </div>
                  </div>
                  <div className="collection details_collection">
                    <div className="chip">{svg.calendar}All time</div>
                    <div className="amount_group">
                      <div className="title">TOTAL SPEND</div>
                      <div className="value">{user?.order?.price || 0}</div>
                    </div>
                    <div className="amount_group">
                      <div className="title">ORDERS</div>
                      <div className="value">{user?.order?.length || 0}</div>
                    </div>
                  </div>
                </div>
                <div className="clinic_group">
                  <div className="header">
                    <div>Clinic</div>
                    {user?.customer?.clinic?.requestOn ? (
                      <div className="date">
                        {dateFormatter(
                          user?.customer?.clinic?.requestOn,
                          "DD MMF,YYYY"
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {user?.clinicDetails?.name ? (
                    <div className="clinic_card">
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${user?.clinicDetails?.doctorPhotoURL})`,
                        }}
                      >
                        {/* <img
                          src={}
                          // src={user?.clinicDetails?.media[0]?.url}
                          alt="clinicPhoto"
                        /> */}
                      </div>
                      <div>
                        <div className="name">{user.clinicDetails.name}</div>
                        <div className="address">
                          {user.clinicDetails.address}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="nothingyet">
                      <CustDropdown
                        {...{
                          ...dropdown_settings,
                          dropdown_align: "left_align",
                          multiselect: false,
                          // all: true,
                          title:
                            selectedClinic.length > 0
                              ? clinicsList.filter(
                                  (el) => el.key == selectedClinic[0]
                                )[0]?.value
                              : "Clinics",
                          value: selectedClinic,
                          setvalue: (value) => setselectedClinic(value),
                          list: [...clinicsList],
                          list_show: (data) => data.value,
                        }}
                      />
                      <div
                        className="btn dark_btn"
                        style={{
                          opacity: selectedClinic.length > 0 ? 1 : 0.4,
                        }}
                        onClick={() => {
                          if (selectedClinic.length > 0) {
                            UpdateClinics(
                              token,
                              selectedClinic[0],
                              user?.customer?._id || "",
                              () => {
                                window.location.reload();
                              },
                              () => {}
                            );
                          }
                        }}
                      >
                        Initiate request
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="right">
                <ActivityTracker
                  filter={["Notes"]}
                  token={token}
                  order_id={customerid}
                  type="customer"
                  data={
                    user?.customer?.notes?.map((el) => ({
                      activity: {
                        ...el,
                        type: "note",
                        createdAt: el.current.addedOn,
                        createdBy: el.current.addedBy,
                      },
                      _id: el._id,
                      orderInfo: {
                        orderId: customerid,
                      },
                    })) || []
                  }
                />
              </div>
            </div>
          ),
          "Care History": (
            <div className="carehistory_body">
              <CustTable
                {...{
                  data: user?.orderItems
                    // ?.filter((el) => {
                    //   // if (order_search) {
                    //   //   return el.customer.name.match(order_search);
                    //   // }
                    //   return 1;
                    // })
                    ?.map(
                      ({
                        orderDate,
                        orderId,
                        product_name,
                        customer,
                        product_status,
                        assigned_to,
                        goTo,
                        product_type,
                        _id,
                        // orderId
                        // orderId,
                      }) => ({
                        userName: customer?.name || "",
                        orderDate: orderDate ? orderDate : "",
                        orderId: orderId ? "#" + orderId : "",
                        productName: product_name || "",
                        orderStatus: product_status ? (
                          <div className="order_status">
                            {product_status || ""}
                          </div>
                        ) : (
                          ""
                        ),
                        assignedTo: assigned_to || "NA",
                        rowClick: () =>
                          product_type !== "guide"
                            ? navigate(
                                "../../../dashboard/orders/" +
                                  orderId +
                                  "/" +
                                  product_type +
                                  "/" +
                                  _id
                              )
                            : "",
                      })
                    ),
                  nosrnogap: true,
                  header: [
                    {
                      id: "orderId",
                      title: "Order Id",
                      // width: 10,
                    },
                    {
                      id: "orderDate",
                      title: "Date",
                      // width: 10,
                    },
                    // {
                    //   id: "userName",
                    //   title: "Customer",
                    //   // width: 20,
                    // },
                    {
                      id: "productName",
                      title: "Product",
                      // width: 30,
                    },
                    {
                      id: "orderStatus",
                      title: "Status",
                      // width: 15,
                    },
                    {
                      id: "assignedTo",
                      title: "Assigned To",
                      // width: 15,
                    },
                  ],
                  onrowclick: (row_data) => {},
                }}
              />
            </div>
          ),
          "Health Record": (
            <div className="healthrecord_body">
              <div className="actions">
                {intake_edit ? (
                  <>
                    <div className="btn " onClick={() => setintake_edit("")}>
                      Cancel
                    </div>
                    <div
                      className="btn dark_btn"
                      style={{
                        opacity:
                          JSON.stringify(intakeold) !== JSON.stringify(intake)
                            ? 1
                            : 0.4,
                      }}
                      onClick={() => {
                        updateIntake(
                          token,
                          customerid,
                          intakeold,
                          () => {
                            window.location.reload();
                          },
                          () => {}
                        );
                        setintake_edit(false);
                      }}
                    >
                      Save
                    </div>
                  </>
                ) : (
                  <div className="btn " onClick={() => setintake_edit(true)}>
                    Edit
                  </div>
                )}
              </div>
              <IntakeView
                type={intake_edit ? "edit" : "view"}
                to_update={intakeold ? { ...intakeold } : {}}
                setto_update={setintakeold}
                data={
                  intake
                    ? {
                        ...intake,
                      }
                    : {}
                }
              />
            </div>
          ),
          Reports: (
            <div className="healthrecord_body">
              <Results {...{ ...reports_obj }} />
            </div>
          ),
          //   Reports: <div>report</div>,
        }[subnav] || ""}
      </div>
    </div>
  );
};

export default CustomerView;
