import React from "react";

const Error = () => {
  if (window.location.pathname == "login") {
    window.location.reload();
  }

  return <div className="error_page">404</div>;
};

export default Error;
