import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustInputMuiClone from "../../components/CustInput/CustInputMuiClone";
import { login } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const { user, flags } = useSelector((state) => state.appState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user_data, setuser_data] = useState({
    email: "",
    password: "",
    email: "health_coach@arva.health",
    // email: "punitesh@arva.health",
    // password: "Admin@123",
    email: "",
    password: "",
  });
  const [NA, setNA] = useState(false);
  const [show_password, setshow_password] = useState(false);
  // useEffect(() => {
  //   // navigate("/dashboard/orders/all");
  // }, []);
  useEffect(() => {
    if (user.token) {
      navigate("/dashboard/orders/all");
    }
  }, [user]);

  return (
    <div className="login">
      <div className="login_main">
        <div>
          <CustInputMuiClone
            {...{
              unique_id: "email",
              attr: {
                error: "",
                error_msg: "",
                value: user_data.email,
                inputMode: "text",
                event: {
                  change: ({ target }) => {
                    setuser_data((prev) => ({ ...prev, email: target.value }));
                    setNA(false);
                  },
                  onkeydown: (e) => {
                    if (e.key == "Enter") {
                    }
                  },
                },
              },
              data: {
                placeholder: "Email",
                suffix: "",
              },
            }}
          />
          <CustInputMuiClone
            {...{
              unique_id: "password",
              attr: {
                error: "",
                error_msg: "",
                value: user_data.password,
                inputMode: "text",
                type: show_password ? "text" : "password",
                event: {
                  change: ({ target }) => {
                    setuser_data((prev) => ({
                      ...prev,
                      password: target.value,
                    }));
                    setNA(false);
                  },
                  onkeydown: (e) => {
                    if (e.key == "Enter") {
                      login(
                        {
                          email: user_data.email,
                          password: user_data.password,
                        },
                        ({ token, name, type }) => {
                          if (token) {
                            dispatch({
                              type: "setAppState",
                              subType: "user",
                              payload: { name, type, token },
                            });
                            navigate("/dashboard/orders/all");
                          } else {
                            setNA(false);
                          }
                        },
                        ({ token, name, type }) => {
                          setNA(true);
                        }
                      );
                    }
                  },
                },
              },
              data: {
                placeholder: "Password",
                suffix: (
                  <div>
                    <input
                      type="checkbox"
                      checked={!show_password}
                      onClick={() => setshow_password((prev) => !prev)}
                    />
                  </div>
                ),
              },
            }}
          />
          <div
            className="btn"
            onClick={() => {
              login(
                {
                  email: user_data.email,
                  password: user_data.password,
                },
                ({ token, name, type }) => {
                  if (token) {
                    dispatch({
                      type: "setAppState",
                      subType: "user",
                      payload: { name, type, token },
                    });
                    // navigate("/dashboard/orders/all");
                  } else {
                    setNA(false);
                  }
                },
                ({ token, name, type }) => {
                  setNA(true);
                }
              );
            }}
          >
            Sign In
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
