import React from "react";

export const DifferentSelector = ({ list, onMultiClick, acceptList }) => {
  return (
    <>
      {acceptList?.length > 0 && (
        <div className="different_selector_main_active">
          {acceptList?.map((el, i) => (
            <div
              className="different_selector_inner_active"
              onClick={() => onMultiClick(el)}
              key={i}
            >
              {el}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.43345 3.43417C3.58347 3.2842 3.78692 3.19994 3.99905 3.19994C4.21118 3.19994 4.41463 3.2842 4.56465 3.43417L7.99905 6.86857L11.4334 3.43417C11.5072 3.35776 11.5955 3.29682 11.6931 3.25489C11.7907 3.21296 11.8957 3.19089 12.0019 3.18997C12.1082 3.18905 12.2135 3.20929 12.3118 3.24951C12.4101 3.28974 12.4994 3.34914 12.5746 3.42426C12.6497 3.49937 12.7091 3.58869 12.7493 3.68701C12.7895 3.78533 12.8098 3.89067 12.8088 3.99689C12.8079 4.10312 12.7859 4.20809 12.7439 4.3057C12.702 4.4033 12.6411 4.49157 12.5646 4.56537L9.13025 7.99977L12.5646 11.4342C12.7104 11.5851 12.791 11.7871 12.7892 11.9969C12.7874 12.2066 12.7032 12.4073 12.5549 12.5556C12.4066 12.704 12.2059 12.7881 11.9962 12.7899C11.7864 12.7917 11.5843 12.7111 11.4334 12.5654L7.99905 9.13097L4.56465 12.5654C4.41377 12.7111 4.21168 12.7917 4.00193 12.7899C3.79217 12.7881 3.59152 12.704 3.44319 12.5556C3.29487 12.4073 3.21073 12.2066 3.20891 11.9969C3.20709 11.7871 3.28772 11.5851 3.43345 11.4342L6.86785 7.99977L3.43345 4.56537C3.28347 4.41535 3.19922 4.2119 3.19922 3.99977C3.19922 3.78764 3.28347 3.58419 3.43345 3.43417Z"
                  fill="#454545"
                />
              </svg>
            </div>
          ))}
        </div>
      )}
      <div className="different_selector_main">
        {list
          ?.filter((item) => !acceptList?.includes(item))
          ?.map((el, i) => (
            <div
              className="different_selector_inner"
              key={i}
              onClick={() => onMultiClick(el)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.99844 2.3999C8.21061 2.3999 8.41409 2.48419 8.56412 2.63422C8.71415 2.78425 8.79844 2.98773 8.79844 3.1999V7.1999H12.7984C13.0106 7.1999 13.2141 7.28419 13.3641 7.43422C13.5142 7.58425 13.5984 7.78773 13.5984 7.9999C13.5984 8.21208 13.5142 8.41556 13.3641 8.56559C13.2141 8.71562 13.0106 8.7999 12.7984 8.7999H8.79844V12.7999C8.79844 13.0121 8.71415 13.2156 8.56412 13.3656C8.41409 13.5156 8.21061 13.5999 7.99844 13.5999C7.78626 13.5999 7.58278 13.5156 7.43275 13.3656C7.28272 13.2156 7.19844 13.0121 7.19844 12.7999V8.7999H3.19844C2.98626 8.7999 2.78278 8.71562 2.63275 8.56559C2.48272 8.41556 2.39844 8.21208 2.39844 7.9999C2.39844 7.78773 2.48272 7.58425 2.63275 7.43422C2.78278 7.28419 2.98626 7.1999 3.19844 7.1999H7.19844V3.1999C7.19844 2.98773 7.28272 2.78425 7.43275 2.63422C7.58278 2.48419 7.78626 2.3999 7.99844 2.3999Z"
                  fill="#454545"
                />
              </svg>
              {el}
            </div>
          ))}
      </div>
    </>
  );
};
