import React, { useEffect, useState } from "react";
import {
  createDiscounts,
  editDiscounts,
  getDiscounts,
  getProducts,
} from "../../../../services/api";
import { useSelector } from "react-redux";
import CustDropdown from "../../../../components/CustInput/CustDropdown";
import { dateFormatter, global_filters } from "../../../../utils/utils";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustInputMuiClone from "../../../../components/CustInput/CustInputMuiClone";
import { MultiSelectDropDown } from "../../../../components/CustInput/MultiSelectDropDown";
import CustTable from "../../../../components/Table/CustTable";

const Discounts = () => {
  const {
    user: { token },
    products,
  } = useSelector((state) => state.appState);
  // const store = useSelector((state) => state);
  //
  const [products_items, setproducts_items] = useState([]);
  const [discounts, setdiscounts] = useState([]);
  const [discount_model, setdiscount_model] = useState("");
  const [order_search, setorder_search] = useState("");
  const [discount_edit, setdiscount_edit] = useState({
    code: "",
    type: "percent",
    value: "",
    appliesTo: ["All"],
    isActive: true,
  });
  const [old_discount_edit, setolddiscount_edit] = useState({
    code: "",
    type: "percent",
    value: "",
    appliesTo: ["All"],
    isActive: true,
  });

  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");
  const [filters, setfilters] = useState({
    discount: ["all"],
  });

  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  useEffect(() => {
    getDiscounts(
      token,
      (success) => {
        if (success?.discounts) {
          //
          setdiscounts(success.discounts);
        }
      },
      (err) => {
        //
      }
    );
    return () => {};
  }, []);
  useEffect(() => {
    if (products.length) {
      setproducts_items(
        products.map(({ sku, name }) => ({ value: sku, label: name }))
      );
      // setproducts_items()
    }
  }, [products]);

  return (
    <div className="dashboard_body discount_page">
      <div className="dashboard_subheader">
        <div className="title">Discounts</div>
        <CustSearch
          {...{
            value: order_search,
            setvalue: setorder_search,
          }}
        />
        {/* <CustDropdown
          {...{
            ...dropdown_settings,
            dropdown_align: "left_align",
            multiselect: false,
            all: true,
            title: "State",
            value: filters.discount,
            setvalue: (value) =>
              setfilters((prev) => ({
                ...prev,
                discount: value,
              })),
            list: global_filters.discount,
            list_show: (data) => data.value,
          }}
        /> */}
        <div
          className="btn"
          onClick={() => {
            setdiscount_model("add");
            setdiscount_edit({
              code: "",
              type: "percent",
              value: "",
              appliesTo: ["All"],
              isActive: true,
            });
          }}
        >
          Add
        </div>
      </div>
      <CustTable
        {...{
          data: discounts
            .filter((el) => {
              if (order_search) {
                return el.customer.name.match(order_search);
              }
              return 1;
            })
            .map(
              ({
                code,
                isActive,
                type,
                usedBy,
                value,
                appliesTo,
                _id,
                createdAt,
              }) => ({
                code,
                // isActive,
                // type,
                date: createdAt ? dateFormatter(createdAt, "DD/MM/YYYY") : "",
                usedBy: usedBy || "0",
                value: value
                  ? type == "percent"
                    ? value + " %"
                    : "INR " + value
                  : "0",
                orderStatus: (
                  <div className="discount_status" active={isActive.toString()}>
                    {isActive ? "Active" : "Draft"}
                  </div>
                ),
                // assignedTo: lineItem?.assignedTo || "NA",
                // + lineItem?.goTo
                rowClick: () => {
                  setdiscount_model("edit");
                  setdiscount_edit({
                    code,
                    isActive,
                    type,
                    value,
                    appliesTo,
                    _id,

                    // appliesTo:
                    //   appliesTo.length == products_items.length ? [] : appliesTo,
                  });
                  setolddiscount_edit({
                    code,
                    isActive,
                    type,
                    value,
                    appliesTo,
                    // appliesTo:
                    //   appliesTo.length == products_items.length ? [] : appliesTo,
                    _id,
                  });
                },
                // lineItem?.goTo &&
                // navigate("../orders/" + (orderId || "-") + lineItem.goTo),
              })
            ),
          nosrnogap: true,
          header: [
            {
              id: "code",
              title: "Discount Code",
              // width: 10,
            },
            {
              id: "value",
              title: "Value",
              // width: 10,
            },
            {
              id: "date",
              title: "Date",
              // width: 20,
            },
            {
              id: "usedBy",
              title: "Used By (customer)",
              // width: 30,
            },
            {
              id: "orderStatus",
              title: "Status",
              // width: 15,
            },
          ],
        }}
      />
      {discount_model ? (
        <div className="discount_model" onClick={() => setdiscount_model("")}>
          <div className="model_main" onClick={(e) => e.stopPropagation()}>
            <div className="active_main">
              <div>This discount is</div>
              <span>{discount_edit.isActive ? "Active" : "Deactive"}</span>
              <div
                className="is_active"
                active={(!discount_edit.isActive).toString()}
                onClick={() =>
                  setdiscount_edit((prev) => ({
                    ...prev,
                    isActive: !prev.isActive,
                  }))
                }
              >
                {!discount_edit.isActive ? "Activate" : "Deactivate"}
              </div>
            </div>
            <div className="line"></div>
            <div className="title">Discount code</div>
            <CustInputMuiClone
              {...{
                unique_id: "discount_code",
                attr: {
                  error: "",
                  error_msg: "",
                  value: discount_edit.code,
                  inputMode: "text",
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setdiscount_edit((prev) => ({
                        ...prev,
                        code: target.value.toUpperCase().replaceAll(" ", ""),
                      }));
                    },
                    onkeydown: (e) => {},
                  },
                },
                data: {
                  placeholder: "DISCOVER10",
                  suffix: "",
                },
              }}
            />
            <div className="title">Type</div>
            <div className="percent_group">
              <div
                className="items"
                onClick={() =>
                  setdiscount_edit((prev) => ({ ...prev, type: "percent" }))
                }
              >
                <div
                  className={
                    "switch" +
                    (discount_edit.type == "percent" ? " active" : "")
                  }
                ></div>
                <div className="txt">Percentage</div>
              </div>
              <div
                className="items"
                onClick={() =>
                  setdiscount_edit((prev) => ({ ...prev, type: "fixed" }))
                }
              >
                <div
                  className={
                    "switch" +
                    (discount_edit.type != "percent" ? " active" : "")
                  }
                ></div>
                <div className="txt">Fixed Amount</div>
              </div>
            </div>
            <div className="title">Applies To</div>

            <MultiSelectDropDown
              custclass=""
              inp_obj={{
                options: [...products_items],
                value: discount_edit.appliesTo,
                drop_list: products_items.map((el, i) => (
                  <div
                    className={
                      "inner_cust_simple_drop_down_list_inner " +
                      (discount_edit.appliesTo.includes(el.value)
                        ? " active_drop_down"
                        : "")
                    }
                    key={i}
                    onClick={(e) => {
                      if (discount_edit.appliesTo.includes(el.value)) {
                        setdiscount_edit((prev) => ({
                          ...prev,
                          appliesTo: discount_edit.appliesTo.filter(
                            (el2) => el2 !== el.value
                          ),
                        }));
                      } else {
                        setdiscount_edit((prev) => ({
                          ...prev,
                          appliesTo: [
                            ...discount_edit.appliesTo.filter(
                              (el) => el != "All"
                            ),
                            el.value,
                          ],
                        }));
                      }
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div>{el.label}</div>
                  </div>
                )),
              }}
            />
            <div className="title">Discount Value</div>
            <CustInputMuiClone
              {...{
                unique_id: "discount_value",
                attr: {
                  error: "",
                  error_msg: "",
                  value: discount_edit.value,
                  inputMode: "text",
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (isNaN(Number(target.value))) {
                        return;
                      }
                      setdiscount_edit((prev) => ({
                        ...prev,
                        value:
                          discount_edit.type == "percent"
                            ? target.value >= 100
                              ? 100
                              : target.value
                            : target.value,
                      }));
                    },
                    onkeydown: (e) => {},
                  },
                },
                data: {
                  placeholder: "10",
                  suffix: "",
                },
              }}
            />
            {/* {JSON.stringify(discount_edit)} */}
            <div className="footer">
              <div
                className="btn"
                onClick={() => {
                  setdiscount_model("");
                }}
              >
                Cancel
              </div>
              {discount_model == "add" ? (
                <div
                  className="btn save_btn"
                  style={
                    discount_edit.code && discount_edit.value
                      ? {}
                      : { opacity: 0.5 }
                  }
                  onClick={() => {
                    createDiscounts(
                      token,
                      discount_edit,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    );
                  }}
                >
                  Save
                </div>
              ) : (
                <div
                  className="btn save_btn"
                  style={
                    discount_edit.code &&
                    discount_edit.value &&
                    JSON.stringify(Object.values(discount_edit)) !==
                      JSON.stringify(Object.values(old_discount_edit))
                      ? {}
                      : { opacity: 0.5 }
                  }
                  onClick={() => {
                    editDiscounts(
                      token,
                      {
                        ...discount_edit,
                        appliesTo:
                          discount_edit.appliesTo.length == 0
                            ? products_items.map((el) => el.value)
                            : discount_edit.appliesTo,
                      },
                      old_discount_edit._id,
                      (success) => {
                        window.location.reload();
                      },
                      (err) => {}
                    );
                  }}
                >
                  Save
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Discounts;
