import React from "react";
import { useParams } from "react-router-dom";
import OrderTypeHeader from "../OrderTypeHeader";
import { useState } from "react";

const Guide = () => {
  const { id } = useParams();
  const [nav, setnav] = useState("");
  return (
    <div>
      <OrderTypeHeader
        {...{
          breadCrumbs: [{ name: "OrderId", goto: "" }, { name: "ProductName" }],
          orderDate: "1 May 2024",
          customer: {
            name: "Nidhi Panchmal",
            goTo: "/nidhi-panchmal",
          },
          nav,
          setnav,
        }}
      />
      Guide - {id}
    </div>
  );
};

export default Guide;
