import React from "react";
import { useSelector } from "react-redux";

const Reports = () => {
  const store = useSelector((state) => state);
  if (store?.appState?.user?.type !== "Admin") {
    return "";
  }
  return (
    <div className="reports_page " style={{ padding: 0, overflow: "hidden" }}>
      <iframe
        src="https://reports.arva.health/?Arvahealth@123"
        height="100%"
        width="100%"
        style={{
          border: 0,
          overflow: "hidden",
        }}
      ></iframe>
    </div>
  );
};

export default Reports;
