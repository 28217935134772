import React, { useEffect, useState } from "react";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustDropdown from "../../../../components/CustInput/CustDropdown";
import { global_filters } from "../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, updateAssignedTo } from "../../../../services/api";
import { svg } from "../../../../utils/svg";
import CustTable from "../../../../components/Table/CustTable";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import { all } from "axios";

const Orders = ({ page }) => {
  const navigate = useNavigate();

  const {
    products,
    bundles,
    user: { token, name },
    employee = [],
    allorders,
    allordersfilters,
  } = useSelector((state) => state.appState);

  const store = useSelector((state) => state.appState);

  const show_items = 10;
  const start = 0;
  const [pagination, setpagination] = useState({
    start: start,
    end: start + show_items,
  });
  const dispatch = useDispatch();
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");
  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const [filter_sets, setfilter_sets] = useState({
    products: [],
    bundles: [],
  });

  const [order_search, setorder_search] = useState("");
  const [filters, setfilters] = useState({
    date: ["all"],
    product_id: ["all"],
    status: ["all"],
    priority: ["all"],
    users: ["all"],
  });

  useEffect(() => {
    if (allordersfilters) {
      setfilters({
        ...allordersfilters,
      });
    }
  }, []);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("search")) {
      setorder_search(searchParams.get("search"));
    }
    let t_filters = { ...filters };
    ["product_id", "status", "priority", "users"].map((el) => {
      if (searchParams.get(el)) {
        t_filters[el] = JSON.parse(searchParams.get(el));
      }
    });
    setfilters(t_filters);
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: "setAppState",
      subType: "allordersfilters",
      payload: {
        ...filters,
      },
    });
  }, [filters]);

  const [orders, setorders] = useState([]);
  const [orderItems, setorderItems] = useState([]);
  const [filterd_data, setfilterd_data] = useState([[], [], [], []]);
  //  1:active, 2:assigned to me , 3:action required
  const [current_filter, setcurrent_filter] = useState(page ? Number(page) : 0);

  useEffect(() => {
    if (["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())) {
      navigate("../orders/assigned-to-me");
      setcurrent_filter(2);
    } else {
      setcurrent_filter(page ? Number(page) : 0);
    }
  }, [page, store]);
  useEffect(() => {
    let array_length = current_filter
      ? filterd_data[current_filter].length
      : orders.length;
    setpagination({
      start: start,
      end:
        array_length < start + show_items ? array_length : start + show_items,
    });
  }, [page, current_filter, filters, orders, filterd_data]);

  useEffect(() => {
    if (products?.length > 0 || bundles?.length > 0) {
      setfilter_sets((prev) => ({
        products: [
          ...products.map((el) => ({
            key: el.sku,
            value: el.name,
          })),
          ...bundles.map((el) => ({
            key: el.sku,
            value: el.name,
            includes: el.servicesIncluded.map((el) => ({
              key: el.sku,
              value: el.name,
            })),
          })),
        ].sort((a, b) => String(a.key).localeCompare(b.key)),
      }));
    }
  }, [products, bundles]);
  useEffect(() => {
    if (allorders?.length > 0) {
      setorders(allorders);
    }

    getOrders(
      token,
      ({ orderItems, orders, priority, status, users }) => {
        setfilter_sets((prev) => ({
          ...prev,
          priority: priority.map((el) => ({
            key: el,
            value: el,
          })),
          status: status.map((el) => ({
            key: el,
            value: el,
          })),
          users: users.map((el) => ({
            key: el,
            value: el,
          })),
        }));
        setorderItems(orderItems);
        setorders(orders);
        dispatch({
          type: "setAppState",
          subType: "allorders",
          payload: [...orders],
        });
        if (
          ["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())
        ) {
          setcurrent_filter(2);
          navigate("../dashboard/orders/all");
        }
      },
      (err) => {}
    );
  }, []);
  useEffect(() => {
    if (orderItems.length) {
      let numbers = [[], [], []];
      orderItems.map((el) => {
        let active = el?.status?.toLowerCase() !== "order completed";
        let assigned_to_me =
          el?.assignedTo?.toLowerCase() === name?.toLowerCase();
        let actoin_required = el?.priority?.toLowerCase() === "high";
        if (active) {
          numbers[0].push(el);
        }
        if (assigned_to_me) {
          numbers[1].push(el);
        }
        if (actoin_required) {
          numbers[2].push(el);
        }
        return null;
      });
      setfilterd_data([[], ...numbers]);
    } else {
      setfilterd_data([[], [], [], []]);
    }
  }, [orderItems, store]);
  const setFilterSerach = (key, value) => {
    let url = new URL(window.location.href);
    url.searchParams.set(key, JSON.stringify(value));
    // ["product_id", "status", "priority", "users"].map((el) =>
    // );
    window.history.pushState({}, "", url);
  };
  return (
    <div className="dashboard_body orders_page">
      <div className="dashboard_subheader">
        <div className="title">Orders</div>
        {/* <CustDropdown
          {...{
            ...dropdown_settings,
            dropdown_align: "left_align",
            multiselect: false,
            all: true,
            title: "Date",
            value: filters.date,
            setvalue: (value) =>
              setfilters((prev) => ({
                ...prev,
                date: value,
                })),
                list: global_filters.date,
                list_show: (data) => data.value,
                }}
                /> */}
        <div className="search_and_filter">
          <CustSearch
            {...{
              value: order_search,
              setvalue: (value) => {
                let url = new URL(window.location.href);
                url.searchParams.set("search", value || "");
                window.history.pushState({}, "", url);
                setorder_search(value);
              },
            }}
          />
          {!["counselor", "doctor"].includes(
            store?.user?.type?.toLowerCase()
          ) ? (
            <>
              <CustDropdown
                {...{
                  ...dropdown_settings,
                  multiselect: true,
                  all: true,
                  title: "Product",
                  value: filters.product_id,
                  setvalue: (value) => {
                    setfilters((prev) => ({
                      ...prev,
                      product_id: value,
                    }));
                    setFilterSerach("product_id", value);
                  },
                  list: filter_sets.products,
                  list_show: (data) => data.value,
                }}
              />
              <CustDropdown
                {...{
                  ...dropdown_settings,
                  multiselect: true,
                  all: true,
                  // default_item: "ft01",
                  title: "Status",
                  value: filters.status,
                  setvalue: (value) => {
                    setfilters((prev) => ({
                      ...prev,
                      status: value,
                    }));
                    setFilterSerach("status", value);
                  },
                  list: filter_sets.status,
                  list_show: (data) => data.value,
                }}
              />
              <CustDropdown
                {...{
                  ...dropdown_settings,
                  multiselect: true,
                  all: true,
                  // default_item: "ft01",
                  title: "Priority",
                  value: filters.priority,
                  setvalue: (value) => {
                    setfilters((prev) => ({
                      ...prev,
                      priority: value,
                    }));
                    setFilterSerach("priority", value);
                  },
                  list: filter_sets.priority,
                  list_show: (data) => data.value,
                }}
              />
              <CustDropdown
                {...{
                  ...dropdown_settings,
                  multiselect: true,
                  all: true,
                  // default_item: "ft01",
                  title: "Assign to",
                  value: filters.users,
                  setvalue: (value) => {
                    setfilters((prev) => ({
                      ...prev,
                      users: value,
                    }));
                    setFilterSerach("users", value);
                  },
                  list: filter_sets.users,
                  list_show: (data) => data.value,
                }}
              />
              <div
                className="btn dark_btn"
                onClick={() => {
                  let url = new URL(window.location.href);
                  ["product_id", "status", "priority", "users", "search"].map(
                    (el) => url.searchParams.delete(el)
                  );
                  window.history.pushState({}, "", url);
                  setfilters((prev) => ({
                    ...prev,
                    product_id: ["all"],
                    status: ["all"],
                    priority: ["all"],
                    users: ["all"],
                  }));
                  setorder_search("");
                }}
              >
                Clear all
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div
            className="btn"
            onClick={() => {
              let data = orderItems.map((el) => [
                el?.order?.orderId || "",
                el?.dates?.order || "",
                el?.customer?.name || "",
                el?.customer?.phone || "",
                el?.product?.name || "",
                el?.status || " ",
                el?.assignedTo || "",
              ]);
              arrayToCSV(data);
              function arrayToCSV(data) {
                // Join each array item with commas, then join each line with a newline character

                let csvContent =
                  "OrderId,Date,Customer name,Phone,Product,,Status,Assigned To".toUpperCase() +
                  "\n";
                csvContent += data.map((row) => row.join(",")).join("\n");

                // Create a Blob object representing the data as a CSV file
                const blob = new Blob([csvContent], {
                  type: "text/csv;charset=utf-8;",
                });

                // Create a link element
                const link = document.createElement("a");

                // Create a URL for the Blob and set it as the href attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "data.csv");

                // Append the link to the document body and programmatically click it to trigger download
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);
              }
            }}
          >
            Download
          </div> */}
        </div>
      </div>
      <div className="dashboard_subbody">
        {!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase()) ? (
          <div className="cards_group">
            <div
              className={
                "card card_type2 " + (current_filter === 0 ? "card_dark" : "")
              }
              onClick={() => navigate("../orders/all")}
            >
              <div className="card_header">
                <div>Sales</div>
                {/* <div className="percentage_group"> {svg.arrow()}12%</div> */}
              </div>
              <div className="card_body">
                <div>{orders?.length || 0}</div>
                {/* <div>₹17,160.00</div> */}
              </div>
            </div>
            <div
              className={"card " + (current_filter === 1 ? "card_dark" : "")}
              onClick={() => navigate("../orders/active")}
            >
              <div className="card_header">
                Active
                {/* {`(time-period)`} */}
              </div>
              <div className="card_body">{filterd_data[1].length}</div>
            </div>
            <div className="divider"></div>
            <div
              className={"card " + (current_filter === 2 ? "card_dark" : "")}
              onClick={() => navigate("../orders/assigned-to-me")}
            >
              <div className="card_header">Assigned to me</div>
              <div className="card_body">{filterd_data[2].length}</div>
            </div>
            <div
              className={"card " + (current_filter === 3 ? "card_dark" : "")}
              onClick={() => navigate("../orders/action-required")}
            >
              <div className="card_header">Action required</div>
              <div className="card_body">{filterd_data[3].length}</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="order_table"
          style={{
            "--h": !["counselor", "doctor"].includes(
              store?.user?.type?.toLowerCase()
            )
              ? 270
              : 150,
          }}
        >
          {current_filter ? (
            <CustTable
              {...{
                data:
                  !current_filter || !filterd_data[current_filter]
                    ? []
                    : [...filterd_data[current_filter]]
                        ?.filter((el) => {
                          let data = [
                            el?.customer?.name || "",
                            el?.customer?.phone || "",
                            el?.dates?.order || "",
                            el?.order?.orderId || "",
                            el?.product?.name || "",
                            el?.status || "",
                            el?.assignedTo || "",
                          ];
                          let search = order_search
                            ? JSON.stringify(data)
                                .toLowerCase()
                                .includes(order_search.toLowerCase())
                            : true;

                          let product_name = !filters?.product_id?.includes(
                            "all"
                          )
                            ? filters?.product_id?.includes(
                                el?.product?.sku || "NA"
                              )
                            : true;
                          let status = !filters?.status?.includes("all")
                            ? filters?.status?.includes(
                                el?.status ? el?.status : "NA"
                              )
                            : true;
                          let priority = !filters?.priority?.includes("all")
                            ? filters?.priority?.includes(el?.priority || "NA")
                            : true;
                          let assigned_to = !filters?.users?.includes("all")
                            ? filters?.users?.includes(el?.assignedTo || "NA")
                            : true;

                          return (
                            search &&
                            product_name &&
                            status &&
                            priority &&
                            assigned_to
                          );
                        })
                        .slice(pagination.start, pagination.end)
                        .map(
                          ({
                            order,
                            orderId,
                            lineItemId,
                            customer,
                            dates,
                            product,
                            status,
                            goTo,
                            assignedTo,
                            priority,
                          }) => ({
                            userName: customer?.name || "",
                            orderDate: dates?.order ? dates.order : "",
                            orderId: order?.orderId ? "#" + order?.orderId : "",
                            productName: product?.name || "",
                            orderStatus: status ? (
                              <div
                                className="order_status chips"
                                {...{
                                  color: {
                                    High: 2,
                                    Medium: 4,
                                    Low: "",
                                  }[priority],
                                }}
                              >
                                {status || ""}
                              </div>
                            ) : (
                              // <div className="order_status" >
                              //   {priority || ""}
                              // </div>
                              ""
                            ),
                            assignedTo: assignedTo ? (
                              <CustDropdown
                                {...{
                                  ...dropdown_settings,
                                  dropdown_align: "",
                                  multiselect: false,
                                  // all: true,
                                  hide_title: true,
                                  title: goTo,
                                  value: assignedTo,
                                  setvalue: (value) => {
                                    if (value[0]) {
                                      updateAssignedTo(
                                        token,
                                        value[0],
                                        goTo || "",
                                        (success) => {
                                          if (success?.success) {
                                            setorderItems((prev) => {
                                              return prev.map((el) => {
                                                if (
                                                  el.lineItemId === lineItemId
                                                ) {
                                                  return {
                                                    ...el,
                                                    assignedTo: value[0],
                                                  };
                                                } else {
                                                  return el;
                                                }
                                              });
                                            });
                                            // window.location.reload();
                                          }
                                        },
                                        () => {}
                                      );
                                    }
                                  },
                                  list: [
                                    ...employee?.map((el) => ({
                                      key: el,
                                      value: el,
                                    })),
                                  ],
                                  list_show: (data) => data.value,
                                }}
                              />
                            ) : (
                              "NA"
                            ),
                            // + lineItem?.goTo
                            rowClick: () => {
                              if (goTo.includes("guide")) {
                                return;
                              }
                              return (
                                goTo &&
                                navigate("../orders/" + (orderId || "-") + goTo)
                              );
                            },
                          })
                        ),
                nosrnogap: true,
                header: [
                  {
                    id: "orderId",
                    title: "Order Id",
                    // width: 10,
                  },
                  {
                    id: "orderDate",
                    title: "Date",
                    // width: 10,
                  },
                  {
                    id: "userName",
                    title: "Customer",
                    // width: 20,
                  },
                  {
                    id: "productName",
                    title: "Product",
                    // width: 30,
                  },
                  {
                    id: "orderStatus",
                    title: "Status",
                    // width: 15,
                  },
                  ...(!["counselor", "doctor"].includes(
                    store?.user?.type?.toLowerCase()
                  )
                    ? [
                        {
                          id: "assignedTo",
                          title: "Assigned To",
                          // width: 15,
                        },
                      ]
                    : []),
                ].flat(),
                onrowclick: (row_data) => {},
              }}
            />
          ) : (
            <>
              <CustTable
                {...{
                  data: orders
                    .filter((el) => {
                      let data = [
                        el?.customer?.name || "",
                        el?.customer?.phone || "",
                        el?.orderDate || "",
                        el?.orderId || "",
                        el?.lineItem?.product_name || "",
                        el?.lineItem?.status || "",
                        el?.lineItem?.assignedTo || "",
                      ];
                      let search = order_search
                        ? JSON.stringify(data)
                            .toLowerCase()
                            .includes(order_search.toLowerCase())
                        : true;
                      let product_name = !filters?.product_id?.includes("all")
                        ? filters?.product_id?.includes(
                            el?.lineItem?.product_id || "NA"
                          )
                        : true;
                      let status = !filters?.status?.includes("all")
                        ? filters?.status?.includes(
                            el?.lineItem?.status ? el?.lineItem?.status : "NA"
                          )
                        : true;
                      let priority = !filters?.priority?.includes("all")
                        ? filters?.priority?.includes(
                            el?.lineItem?.priority || "NA"
                          )
                        : true;
                      let assigned_to = !filters?.users?.includes("all")
                        ? filters?.users?.includes(
                            el?.lineItem?.assignedTo || "-"
                          )
                        : true;
                      return (
                        search &&
                        product_name &&
                        status &&
                        priority &&
                        assigned_to
                      );
                    })
                    .slice(pagination.start, pagination.end)
                    .map(({ orderDate, orderId, lineItem, customer }) => ({
                      userName: customer?.name || "",
                      orderDate: orderDate ? orderDate : "",
                      orderId: orderId ? "#" + orderId : "",
                      productName: lineItem?.product_name || "",
                      orderStatus: lineItem?.status ? (
                        <div
                          className="order_status chips"
                          {...{
                            color: {
                              High: 2,
                              Medium: 4,
                              Low: "",
                            }[lineItem?.priority],
                          }}
                        >
                          {lineItem?.status || ""}
                        </div>
                      ) : (
                        ""
                      ),
                      assignedTo: lineItem?.assignedTo || "-",
                      // + lineItem?.goTo
                      rowClick: () => {
                        if (lineItem?.goTo.includes("guide")) {
                          return;
                        }
                        return (
                          lineItem?.goTo &&
                          navigate(
                            "../orders/" + (orderId || "-") + lineItem.goTo
                          )
                        );
                      },
                    })),
                  nosrnogap: true,
                  header: [
                    {
                      id: "orderId",
                      title: "Order Id",
                      // width: 10,
                    },
                    {
                      id: "orderDate",
                      title: "Date",
                      // width: 10,
                    },
                    {
                      id: "userName",
                      title: "Customer",
                      // width: 20,
                    },
                    {
                      id: "productName",
                      title: "Product",
                      // width: 30,
                    },
                    {
                      id: "orderStatus",
                      title: "Status",
                      // width: 15,
                    },
                    ...(!["counselor", "doctor"].includes(
                      store?.user?.type?.toLowerCase()
                    )
                      ? [
                          {
                            id: "assignedTo",
                            title: "Assigned To",
                            // width: 15,
                          },
                        ]
                      : []),
                  ].flat(),
                  onrowclick: (row_data) => {},
                }}
              />
            </>
          )}
        </div>
        <div className="pagination">
          <div
            className="prev"
            onClick={() => {
              setpagination((prev) => {
                let array_length = current_filter
                  ? filterd_data[current_filter].length
                  : orders.length;
                if (prev.start == 0) {
                  return {
                    ...prev,
                  };
                }
                let new_start = prev.start - show_items;
                let new_end = prev.start;
                if (new_start <= 0) {
                  new_start = 0;
                }

                return {
                  start: new_start,
                  end: new_end,
                };
              });
            }}
          >
            {svg.arrow()}
          </div>
          {pagination.start + 1} -{pagination.end}
          <div
            className="next"
            onClick={() => {
              setpagination((prev) => {
                let array_length = current_filter
                  ? filterd_data[current_filter].length
                  : orders.length;
                if (prev.end == array_length) {
                  return {
                    ...prev,
                  };
                }
                let new_start = prev.end;
                let new_end = prev.end + show_items;
                if (new_end >= array_length) {
                  new_end = array_length;
                }

                return {
                  start: new_start,
                  end: new_end,
                };
              });
            }}
          >
            {svg.arrow()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
// let orderitems = {
//   data: orderItems.map(
//     ({
//       product,
//       order,
//       dates,
//       customer,
//       status,
//       assignedTo,
//       goTo,
//     }) => ({
//       orderId: order?.orderId ? "#" + order.orderId : "",
//       orderDate: dates?.order ? dates.order : "",
//       productName: product?.name || "",
//       userName: customer?.name || "",
//       orderStatus: <div className="order_status">{status}</div>,
//       assignedTo: assignedTo,
//       rowClick: () =>
//         navigate("../orders/" + (order?._id || "-") + goTo),
//     })
//   ),
//   nosrnogap: true,
//   header: [
//     {
//       id: "orderId",
//       title: "Order Id",
//       // width: 10,
//     },
//     {
//       id: "orderDate",
//       title: "Date",
//       // width: 10,
//     },
//     {
//       id: "userName",
//       title: "Customer",
//       // width: 20,
//     },
//     {
//       id: "productName",
//       title: "Product",
//       // width: 30,
//     },
//     {
//       id: "orderStatus",
//       title: "Status",
//       // width: 15,
//     },
//     {
//       id: "assignedTo",
//       title: "Assigned To",
//       // width: 15,
//     },
//   ],
//   onrowclick: (row_data) => {
//
//   },
// }
