import React, { useEffect, useRef, useState } from "react";

export const CustDropDownIntake = ({ inp_obj, custclass }) => {
  const [DropOpen, setDropOpen] = useState(false);
  // const [onTop, setonTop] = useState(false);
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   function handleScroll() {
  //     if (dropdownRef.current) {
  //       const dropdownRect = dropdownRef.current.getBoundingClientRect();
  //       const bottomDistance = window.innerHeight - dropdownRect.bottom;
  //       setonTop(bottomDistance < 10);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropOpen(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={"cust_simple_drop_down " + (custclass ? custclass : "")}
        ref={dropdownRef}
      >
        <div
          className="inner_cust_simple_drop_down"
          onClick={(e) => {
            setDropOpen((prev) => !prev);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="inner_cust_simple_drop_down_title">
            {inp_obj.value?.length <= 0 || inp_obj.value == ""
              ? "Select"
              : inp_obj.value}
          </div>
          <div
            className="inner_cust_simple_drop_down_icons"
            style={{
              transform: DropOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform .1s linear",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M5.33203 7.5L10.332 12.5L15.332 7.5"
                stroke="#777777"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {DropOpen && (
          <div
            className="inner_cust_simple_drop_down_list"
            // style={{
            //   bottom: onTop ? "100%" : "unset",
            // }}
          >
            {inp_obj.options.map((el, i) => (
              <div
                className=""
                key={i}
                onClick={(e) => {
                  DropOpen && inp_obj.on_event(el);
                  e.preventDefault();
                  e.stopPropagation();
                  setDropOpen(false);
                }}
              >
                <div>{el}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
