import React from "react";
import { useCalendlyEventListener, PopupModal } from "react-calendly";
import { useSelector } from "react-redux";

const CalendlyModal = ({ url, onSubmit, isOpen, setIsOpen }) => {
  const store = useSelector((state) => state.checktoken_data);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      // return;
      return onSubmit
        ? onSubmit({
            eventUrl: e?.data?.payload?.event?.uri || "",
            inviteeUrl: e?.data?.payload?.invitee?.uri || "",
          })
        : "";
    },
  });
  return (
    <div>
      <PopupModal
        url={url}
        prefill={{
          name: [
            store?.customer?.user?.first_name,
            store?.customer?.user?.last_name,
          ]
            .join(" ")
            .trim(),
          email: store?.customer?.user?.email,
        }}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default CalendlyModal;
