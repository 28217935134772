import React, { useEffect, useState } from "react";
import { svg } from "../../../../../../services/svg";
import { useSelector } from "react-redux";
import CalendlyModal from "../../../../../../components/Calendly/CalendlyModal";
import { dateFormatter } from "../../../../../../utils/utils";

const AppointmentDropdown = ({
  size = 1,
  no_follow,
  appointments,
  link,
  schedule,
  cancel,
  complete,
  order_id,
  addAppoiuntment,
  cancelAppoiuntment,
  createAppoiuntment,
  completeAppoiuntment,
  hide_divider = false,
}) => {
  const {
    user: { token },
  } = useSelector((state) => state.appState);
  const [calendlyModalState, setcalendlyModalState] = useState(false);
  const [calendlyData, setcalendlyData] = useState({
    url: "",
    onSubmit: () => "",
  });
  const [data, setdata] = useState(
    []
    // new Array(size).fill({
    // dateTime: "Friday, May 8, 1:30 pm - 2:00pm",
    // link: "www.gmeet/join",
    // reschedule: "www.gmeet/join",
    // isEnabled:false
    // })
  );
  const [curr_Active, setcurr_Active] = useState([0]);
  useEffect(() => {
    let show_switch = false;
    setdata(
      [...appointments].map((el, i) => {
        if (!appointments[i]?._id && appointments[i - 1]?._id) {
          show_switch = true;
        }
        let obj = {
          name: size == 1 && i == 1 ? "Follow-up" : "",
          dateTime: el?.calendlyData?.startTime
            ? dateFormatter(
                el?.calendlyData?.startTime,
                "dddd, MMF DD, hh:mm A"
              )
            : "",
          link: el?.calendlyData?.location || "",
          reschedule: el?.calendlyData?.rescheduleUrl || "",
          isEnabled: el?.isEnabled,
          id: el._id,
          isComplete: el?.isCompleted,

          show_switch: show_switch,
        };
        if (!appointments[i]?._id && appointments[i - 1]?._id) {
          show_switch = false;
        }
        return obj;
      })
    );
    // if (size == 1) {
    // } else {
    //   setdata(appointments);
    // }
  }, [appointments]);

  return (
    <div className="appointmentdropdown">
      {data.map(
        (
          { name, dateTime, link, isEnabled, id, isComplete, show_switch },
          i
        ) => {
          return (
            <>
              <div
                className="items"
                // active={curr_Active.includes(i) ? "true" : ""}
                active={"true"}
              >
                <div className="session_count">
                  <div className="counter">
                    {name ? name : `Session ${i + 1}/${data.length}`}
                  </div>
                  {/* {!curr_Active.includes(i) && ( */}
                  {!isEnabled && show_switch ? (
                    <div
                      className="switch"
                      // onClick={() => setcurr_Active((prev) => [...prev, i])}
                      onClick={() => {
                        createAppoiuntment(order_id);
                      }}
                    >
                      <div className="switcher"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="accordian_body">
                  {(dateTime || link) && (
                    <div className="item_data">
                      {dateTime && (
                        <div className="address">
                          {svg.location}
                          {dateTime}
                        </div>
                      )}
                      {link && (
                        <div className="link_main">
                          {svg.location}
                          <a href={link} target="_blank" className="link">
                            {link}
                          </a>
                          <div className="copy">{svg.clipboard}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {isEnabled ? (
                    <div className="btn_group">
                      {/* {[!!isComplete, isEnabled].toString()} */}
                      {!isComplete ? (
                        link ? (
                          <>
                            {
                              <div
                                className="btn"
                                onClick={() => {
                                  cancelAppoiuntment(id);
                                }}
                              >
                                Cancel
                              </div>
                            }
                            <div
                              className="complet_btn btn"
                              onClick={() => {
                                completeAppoiuntment(id);
                              }}
                            >
                              Complete
                            </div>
                          </>
                        ) : (
                          <div
                            className="btn dark_btn"
                            onClick={() => {
                              setcalendlyModalState(true);
                              setcalendlyData({
                                // url: items.calendlyData.eventUrl,
                                url: "https://calendly.com/arvahealth/testing-events",
                                onSubmit: (evenetData) => {
                                  addAppoiuntment(id, evenetData);
                                },
                              });
                            }}
                          >
                            Schedule call
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {hide_divider ? "" : <div className="divider"></div>}
            </>
          );
        }
      )}
      {size == 1 && appointments.length == 1 && !no_follow ? (
        <div
          className="items follow_up_main"
          active={curr_Active.includes("followUp") ? "true" : ""}
        >
          <div className="session_count">
            <div className="follow_up">Follow-up</div>
            <div className="optional">Optional</div>
            {appointments.length >= 2 ? (
              ""
            ) : (
              <div
                className="switch"
                onClick={() => {
                  createAppoiuntment(order_id);
                  // setcurr_Active((prev) =>
                  //   prev.includes("followUp")
                  //     ? prev.filter((el) => el != "followUp")
                  //     : [...prev, "followUp"]
                  // );
                }}
              >
                <div
                  className="switcher"
                  style={
                    curr_Active.includes("followUp")
                      ? {
                          left: "unset",
                          right: 0,
                        }
                      : {}
                  }
                ></div>
              </div>
            )}
          </div>
          <div className="accordian_body"></div>
        </div>
      ) : (
        ""
      )}
      <CalendlyModal
        {...{
          ...calendlyData,
          url: link || "https://calendly.com/arvahealth/testing-events",
          isOpen: calendlyModalState,
          setIsOpen: setcalendlyModalState,
        }}
      />
    </div>
  );
};

export default AppointmentDropdown;
