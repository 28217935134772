export const productData = {
  "0001": {
    id: "0001",
    name: "Complete Fertility Test for Women",
    description: "description1",
    price: 6450,
    slashedPrice: 0,
    count: 1,
  },
  "0002": {
    id: "0002",
    name: "Starter Fertility test",
    description: "description2",
    price: 5150,
    slashedPrice: 0,
    count: 1,
  },
  "0003": {
    id: "0003",
    name: "AMH",
    description: "description3",
    price: 1000,
    slashedPrice: 0,
    count: 1,
  },
};

export const discounts = [
  {
    name: "DISCOUNTFT01",
    products: ["FT01"],
    discountvalue: 100,
    percentage: true,
  },
  {
    name: "DISCOUNTFT03",
    products: ["FT02"],
    discountvalue: 500,
    percentage: false,
  },
  {
    name: "DISCOUNTFT0102",
    products: ["FT01", "FT02"],
    discountvalue: 1000,
    percentage: false,
  },
  {
    name: "DISCOUNTFT0103",
    products: ["FT01", "FT02"],
    discountvalue: 500,
    percentage: false,
  },
  {
    name: "DISCOUNTAB03",
    products: ["AB03"],
    discountvalue: 10,
    percentage: true,
  },
];
export const sanityimage = ({ asset = {} }) => {
  let a = asset?._ref?.split("-");
  if (a) {
    return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
      .slice(1, -1)
      .join("-")}.${a[a.length - 1]}`;
  }
  return "";
};
export const NunberCostFormat = (value) => {
  return parseFloat(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
export const input_title = false;
export const global_filters = {
  product_id: [
    // { key: "all", value: "All" },
    { key: "ft01", value: "FT01" },
    { key: "ft02", value: "FT02" },
    { key: "ft03", value: "FT03" },
  ],
  status: ["All", "Status1", "Status2", "Status3"],
  person: ["All", "Person1", "Person2", "Person3"],
  priority: ["All", "Person1", "Person2", "Person3"],
  date: [
    {
      key: "today",
      value: "Today",
    },
    {
      key: "thisWeek",
      value: "This Week",
    },
    {
      key: "thisMonth",
      value: "This Month",
    },
    {
      key: "last3Months",
      value: "Last 3 Months",
    },
    {
      key: "last6Months",
      value: "Last 6 Months",
    },
  ],
  discount: [
    {
      key: "active",
      value: "Active",
    },
    {
      key: "draft",
      value: "Draft",
    },
  ],
};
const paddNumbers = (num, size) => String(num).padStart(size, "0");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dayShortNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const dateFormatter = (dateInput, format) => {
  const date = dateInput ? new Date(dateInput) : null;
  //
  if (!date || date == "Invalid Date") {
    return "";
  }

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12;
  const ampm = hours24 >= 12 ? "PM" : "AM";
  const dayName = dayNames[date.getDay()];
  const dayShortName = dayShortNames[date.getDay()];

  const options = {
    YY: date.getFullYear().toString().slice(-2),
    YYYY: date.getFullYear(),
    MM: date.getMonth() + 1,
    MMF: monthNames[date.getMonth()],
    MMS: monthShortNames[date.getMonth()],
    DD: date.getDate(),
    D: date.getDay(),
    HH: hours24,
    hh: hours12,
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    A: ampm,
    dddd: dayName,
    ddd: dayShortName,
  };

  return format.replace(
    /YYYY|YY|MMF|MMS|MM|DD|D|HH|hh|mm|ss|dddd|ddd|A/g,
    (match) => paddNumbers(options[match], 2)
  );
};
export const formatPhone = (phone, prefix) => {
  const formattedPhone = `${phone?.slice(0, 5)} ${phone?.slice(5)}`;
  return `${prefix ? "+" + prefix + " " : ""}${formattedPhone} `;
};
export const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
export const intake_options = {
  period: [
    "Every 22-35 days",
    "<21 days",
    ">35 days",
    "It’s super unpredictable",
  ],
  period_flow: ["Spotting", "Light", "Moderate", "Heavy"],
  symptoms: [
    "Excess body hair (including your face)",
    "Acne",
    "Hairfall",
    "Irregular periods",
    "Weight fluctuations",
    "IBS",
  ],
  body_hair: [
    "Upper lip",
    "Chin",
    "Chest",
    "Cheeks / Sideburns",
    "Upper abdomen",
    "Lower abdomen",
    "Legs",
    "Arms",
    "Upper back",
    "Lower back",
  ],
  diagnosis: [
    "PCOS",
    "Thyroid disorder (like Hashimoto’s)",
    "Endometriosis",
    "Fibroids",
    "Cysts",
    "Ectopic pregnancy",
    "Fallopian tube blockage",
    "Hypothalamic Amenorrhea (or Hypogonadotropic Hypogonadism)",
    "Pelvic Inflammatory Disease (PID)",
    "Premature ovarian insufficiency (POI)",
    "Other",
  ],
  goal: [
    "Just curious to learn about my fertility",
    "I’m exploring options like Egg Freezing",
    "I want kids but I won’t get started for at least another year",
    "I’m trying for kids as we speak (without assistance)",
    "I’m trying for kids as we speak (with fertility treatment)",
    "I don’t want biological kids. I’m here to learn more about my body.",
  ],
  ultrasound: ["Yes", "No"],
  mom_ease_of_pregnancy: [
    "Easy",
    "Challenging",
    "Challenging due to male factor",
    "I don’t know",
  ],
  partner: [
    "Married",
    "Engaged",
    "Long term relationship",
    "Dating right now",
    "Nope",
  ],
  about_partner: [
    "My partner has sperm",
    "My partner has ovaries",
    "None of the above",
  ],
  know_arva: [
    "Instagram",
    "Google",
    "LinkedIn",
    "Twitter",
    "From a friend or family",
    "News publications",
  ],
};

export const labRangeMaker = (labContent) => {
  return [
    {
      name: "Body Mass Index",
      nameShort: "BMI",
      biomarkerCode: "BMI",
      description:
        "BMI is a measure of body fat based on height and weight that applies to adult men and women. It is used to categorize individuals into various weight statuses, which can indicate potential health risks.",
      insights: [
        {
          _id: "d1a5c0ef-1234-4c5f-987a-12345abc6789",
          score: "Underweight",
          description:
            "A BMI below 18.5 indicates that you are underweight, which may imply a risk of malnutrition or other health issues.",
        },
        {
          _id: "a2b3d4ef-2345-4d6e-890b-23456def7890",
          score: "Normal",
          description:
            "A BMI between 18.5 and 24.9 is considered normal and generally indicates a healthy weight.",
        },
        {
          _id: "c3d4e5f6-3456-4e7f-901c-34567fgh8901",
          score: "Overweight",
          description:
            "A BMI between 25 and 29.9 indicates that you are overweight, which may increase the risk of various health conditions.",
        },
      ],
      labRange: [
        {
          lab: "General Health Guidelines",
          lowMin: 0,
          lowMax: 18.5,
          optimalMin: 18.5,
          optimalMax: 24.9,
          highMin: 25,
          highMax: 29.9,
          unit: "kg/m²",
          // "obeseMin": 30,
          _id: "e5f6g7h8-5678-4g9h-123d-56789ijk0123",
          ageMin: 0,
          ageMax: 100,
        },
      ],
      _id: "f7g8h9i0-6789-4h0i-234d-67890jkl1234",
    },
    ...labContent?.biomarker,
  ]?.map((el) => {
    let value_desc = {
      low: "",
      optimal: "",
      high: "",
    };
    el?.insights?.map(
      (el1) => (value_desc[el1.score.toLowerCase()] = el1.description)
    );
    return {
      name: el.name,
      short_name: el.nameShort,
      biomarkerCode: el.biomarkerCode,
      desc: el.description,

      labRange: (age, og_obj, labname = "thyrocare") => {
        let value = {};
        if (
          el?.labRange?.length === 1 &&
          el?.labRange?.lab?.toLowerCase().match(labname)
        ) {
          value = {
            range: {
              start:
                parseFloat(el.labRange[0].lowMin) === 0
                  ? 0
                  : (og_obj?.min || parseFloat(el.labRange[0].highMin)) <
                    parseFloat(el.labRange[0].highMin)
                  ? og_obj.min - 1 < 0
                    ? 0
                    : og_obj.min - 1
                  : parseFloat(el.labRange[0].highMin),
              min:
                og_obj?.min === 0
                  ? 0
                  : og_obj?.min || parseFloat(el.labRange[0].optimalMin),
              max:
                og_obj?.max === 0
                  ? 0
                  : og_obj?.max || parseFloat(el.labRange[0].optimalMax),
              end:
                (og_obj?.max || 0) > parseFloat(el.labRange[0].highMax)
                  ? og_obj.max + 1
                  : parseFloat(el.labRange[0].highMax),
              unit: el.labRange[0].unit,
            },
          };
        } else {
          for (let i = 0; i < el?.labRange?.length; i++) {
            if (age >= el.labRange[i].ageMin && age <= el.labRange[i].ageMax) {
              value = {
                range: {
                  start:
                    parseFloat(el.labRange[i].lowMin) === 0
                      ? 0
                      : (og_obj?.min || parseFloat(el.labRange[i].highMin)) <
                        parseFloat(el.labRange[i].highMin)
                      ? og_obj.min - 1 < 0
                        ? 0
                        : og_obj.min - 1
                      : parseFloat(el.labRange[i].highMin),
                  min:
                    og_obj?.min === 0
                      ? 0
                      : og_obj?.min || parseFloat(el.labRange[i].optimalMin),
                  max:
                    og_obj?.max === 0
                      ? 0
                      : og_obj?.max || parseFloat(el.labRange[i].optimalMax),
                  end:
                    (og_obj?.max || 0) > parseFloat(el.labRange[i].highMax)
                      ? og_obj.max + 1
                      : parseFloat(el.labRange[i].highMax),
                  unit: el.labRange[i].unit,
                },
              };
            }
          }
        }
        value.value = og_obj?.value || "";
        //
        if (og_obj.value == "") {
          value.score = "pending";
          // value.value_desc = "Enter a value to generate description";
          value.value_desc = "";
        } else if (value?.range && !og_obj?.range) {
          if (
            // og_obj?.value > value.range.start &&
            og_obj?.value < value.range.min
          ) {
            value.score = "low";
            value.value_desc = value_desc.low;
            value.color = 0;
          } else if (
            og_obj?.value >= value.range.min &&
            og_obj.value <= value.range.max
          ) {
            value.score = "optimal";
            value.value_desc = value_desc.optimal;
            value.color = 1;
          } else if (
            og_obj?.value > value.range.max
            //  &&
            // og_obj?.value < value.range.end
          ) {
            value.score = "high";
            value.value_desc = value_desc.high;
            value.color = 2;
          } else {
            value.score = "pending";
            value.value_desc = "Description will be updated shortly";
          }
        } else {
          value.score = og_obj?.score;
          value.value_desc = value_desc[og_obj?.score?.toLowerCase()];
        }

        return value;
      },
    };
  });
};
export const createCards = (card_data, biomarkers, intake) => {
  let pcosChip = [];
  let cards = {};
  card_data.map((el) => {
    if (
      el.name === "Fertility (Egg Count)" ||
      el._id === "3a983a7f-efba-4d62-8f48-61cdb28f2a55" ||
      el.name === "Fertility (Egg Count) - Starter" ||
      el._id === "ad4d54c1-291c-41e5-8794-5dc37379aa69" ||
      el.name === "Fertility (Egg Count) - Only AMH" ||
      el._id === "a89dd380-a927-4565-b9db-8d9dc529a449"
    ) {
      cards.fertility = el;
    } else if (
      el.name === "Signs of PCOS" ||
      el._id === "d4019fa3-e548-47fb-a59a-74441dce5a12"
    ) {
      cards.signs_of_pcos = el;
    } else if (
      el.name === "Thyroid Health" ||
      el._id === "fe54d76c-1016-4ada-aedb-1f93938c2853" ||
      el.name === "Thyroid Health" ||
      el._id === "9c8ce9ac-375d-4c7e-be39-9d35cfe8ea74"
    ) {
      cards.thyroid_health = el;
    }
  });

  let FreetHignMin = {};
  let AMH = {};
  let HomaIRHignMin = {};
  let ThyroHignMin = {};
  let ThyroHignMint4 = {};
  let ThyroHignMint3 = {};

  biomarkers.map((el) => {
    let biomarker_obj = {
      "Free T": (data) => (FreetHignMin = { ...data }),
      AMH: (data) => (AMH = { ...data }),
      "HOMA IR": (data) => (HomaIRHignMin = { ...data }),
      TSH: (data) => (ThyroHignMin = { ...data }),
      T4: (data) => (ThyroHignMint4 = { ...data }),
      T3: (data) => (ThyroHignMint3 = { ...data }),
    };
    return biomarker_obj[el.short_name] ? biomarker_obj[el.short_name](el) : "";
  });

  if (cards?.fertility) {
    let score = AMH?.score?.toLowerCase();
    let FertiltiyEggCount = cards?.fertility?._id
      ? cards?.fertility?.summaryInsight?.filter(
          (el) => el?.title?.toLowerCase() === score
        )[0]
      : {};

    //
    //
    cards.fertility = {
      ...cards.fertility,
      summaryInsight: FertiltiyEggCount,

      chips: score
        ? [
            {
              txt: `${score[0].toUpperCase()}${score.slice(1)} Ovarian Reserve`,
              color: {
                low: 0,
                optimal: 1,
                high: 2,
              }[score],
            },
          ]
        : [],
    };
  }
  const SampleSimpltoms = [...intake_options?.symptoms];
  let tsympt = intake?.menstrual_health?.experience_symptoms?.filter((el) =>
    SampleSimpltoms.includes(el)
  );
  let tlen = intake?.menstrual_health?.symptoms?.filter((el) =>
    [
      "Upper lip",
      "Chest",
      "Chin",
      "Upper abdomen",
      "Lower abdomen",
      "Cheeks / Sideburns",
    ].includes(el)
  );
  if (
    tlen?.length < 2 &&
    tsympt?.includes("Excess body hair (including your face)")
  ) {
    tsympt = tsympt?.filter(
      (el) => el !== "Excess body hair (including your face)"
    );
  }
  let irregularPeriods = [
    "<21 days",
    ">35 days",
    "It’s super unpredictable",
  ].includes(intake?.menstrual_health?.period_frequency);
  let AmhHigh = AMH?.score?.toLowerCase() === "high";
  let PcosDig =
    intake?.reproductive_health?.ultrasound_diagnosis?.toLowerCase() === "yes";
  let FreeTHigh = FreetHignMin?.range === "High";
  let HomaIrHigh = HomaIRHignMin?.range === "High";
  let PhysSympt = tsympt?.length >= 2;

  if (HomaIrHigh) {
    pcosChip.push({ txt: "Insulin-resistant", color: 2 });
  }
  if (PhysSympt && FreeTHigh) {
    pcosChip.push({ txt: "Androgen excess", color: 2 });
  }
  if (AmhHigh) {
    pcosChip.push({ txt: "AMH factor", color: 2 });
  }
  if (cards?.signs_of_pcos) {
    let PCOSYes = {};
    let PCOSNo = {};
    if (cards?.signs_of_pcos?.summaryInsight?.length > 0) {
      cards?.signs_of_pcos?.summaryInsight.map((el) => {
        if (el.title === "Yes") {
          PCOSYes = { ...el };
        } else if (el.title === "No") {
          PCOSNo = { ...el };
        }
        return null;
      });
    }
    cards.signs_of_pcos = {
      ...cards?.signs_of_pcos,
      chips:
        pcosChip.length === 0
          ? [{ txt: "Healthy Hormones", color: 1 }]
          : pcosChip,
    };
    cards.signs_of_pcos = {
      ...cards.signs_of_pcos,
      summaryInsight: PCOSNo,
    };
    if (
      (irregularPeriods && PhysSympt && FreeTHigh) ||
      (irregularPeriods && AmhHigh) ||
      (irregularPeriods && PcosDig) ||
      (PhysSympt && AmhHigh && FreeTHigh) ||
      (PhysSympt && PcosDig && FreeTHigh) ||
      (AmhHigh && PhysSympt)
    ) {
      cards.signs_of_pcos = {
        ...cards.signs_of_pcos,
        summaryInsight: PCOSYes,
      };
    }
  }

  let rangeThyroid = [
    ThyroHignMin?.score,
    ThyroHignMint3?.score || "optimal",
    ThyroHignMint4?.score || "optimal",
    // ThyroHignMint3?.score,
    // ThyroHignMint4?.score,
  ];
  let ThyroidLow = {};
  let ThyroidOptimal = {};
  let ThyroidHigh = {};
  if (cards?.thyroid_health) {
    cards?.thyroid_health?.summaryInsight.map((el) => {
      return {
        Low: () => (ThyroidLow = el),
        Optimal: () => (ThyroidOptimal = el),
        High: () => (ThyroidHigh = el),
      }[el.title]();
    });
    //
    //
    let rangeThyroidSet = [...new Set(rangeThyroid)];
    const Danger = ThyroidDanger;
    if (!rangeThyroid.includes("pending")) {
      if (rangeThyroid.filter((el) => el === "optimal").length >= 2) {
        if (rangeThyroidSet.length == 1 && rangeThyroidSet[0] == "optimal") {
          cards.thyroid_health = {
            ...cards.thyroid_health,
            summaryInsight: ThyroidOptimal,
            chips: [{ txt: "Healthy Thyroid", color: 1 }],
          };
        } else {
          if (
            ["optimal,low", "high,optimal"].includes(rangeThyroidSet.join(","))
          ) {
            cards.thyroid_health = {
              ...cards.thyroid_health,
              summaryInsight: ThyroidHigh,
              chips: [{ txt: "Hypothyroidism", color: 2 }],
            };
          } else if (
            ["low,optimal", "optimal,high"].includes(rangeThyroidSet.join(","))
          ) {
            cards.thyroid_health = {
              ...cards.thyroid_health,
              summaryInsight: ThyroidLow,
              chips: [{ txt: "Hyperthyroidism", color: 0 }],
            };
          }
        }
      } else {
        // Danger
        if (rangeThyroidSet.filter((el) => el).length > 0) {
          cards.thyroid_health = {
            ...cards.thyroid_health,
            summaryInsight: Danger,
            chips: [{ txt: "Danger", color: 3 }],
          };
        }
      }
    }
  }

  const checkNull = (key) => {
    return Array.isArray(cards?.[key]?.summaryInsight) ||
      !cards?.[key]?.summaryInsight
      ? null
      : cards[key];
  };
  return {
    fertility: checkNull("fertility"),
    signs_of_pcos: checkNull("signs_of_pcos"),
    thyroid_health: checkNull("thyroid_health"),
  };
};
const ThyroidDanger = {
  _id: "65cadb6c-09fa-4562-a27a-41c6afee309e",
  title: "Danger",
  heading:
    "Your levels indicate that your Thyroid is may not functioning optimally",
  description:
    "Your thyroid levels could be causing a hormone imbalance that could be an inhabiting factor in fertility.",
  score: null,
  relatedArticles: [
    {
      author: {
        _id: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
        name: "The Arva Team",
      },
      _id: "4b3bfdcc-fac8-4dca-83f8-7587b8b99cf6",
      title:
        "I think I have Hyperthyroidism or Hypothyroidism — What should I know?",
      slug: "i-think-i-have-hyperthyroidism-or-hypothyroidism-what-should-i-know",
      description:
        "Thyroidism doesn’t have to be overwhelming with jargon and medical terms. We’ve laid it out for you, unbiased and uncomplicated 🤎",
      topic: "Fertility Wellness",
      image: "image-d3db8dbe2d83baea482ce0cd51d3c2a69b8c760d-2160x1440-png",
    },
    {
      title: "How does Thyroidism affect my fertility?",
      slug: "how-does-thyroidism-affect-my-fertility",
      description:
        "Hypothyroidism and hyperthyroidism can both have a negative impact on fertility, whether you’re trying to conceive or taking a pregnancy to term. But there are treatment options available.",
      topic: "Fertility Wellness",
      image: "image-68fdbb48516f96957c97257dc8e51e5002938815-2160x1440-png",
      author: {
        _id: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
        name: "The Arva Team",
      },
      _id: "b946f1b0-1bf9-4497-b9fd-71c82a689ee9",
    },
  ],
};
export const fileToBase64 = ({ target }) => {
  const file = target.files[0];
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        // const base64String = e.target.result.split(",")[1]; // Base64 string
        const base64String = e.target.result; // Base64 string
        resolve({
          name: file.name.split(".").slice(0, -1).join("."),
          base64: base64String,
        });
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  } else {
    return Promise.reject(new Error("No file selected"));
  }
};
const calendlyUrlByPerson = [
  {
    name: "Rishina Bansal",
    link: "https://calendly.com/rishina/fertility-health-consultation",
  },
  {
    name: "Dr. Aditi Tandon",
    link: "https://calendly.com/dradititandon/fertility-health-consultation",
  },
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr.name.toLowerCase()]: curr,
  }),
  {
    "dr. shivani fhc": {
      name: "Dr. Shivani",
      link: "https://calendly.com/drshivani-arva/fertility-consultation",
    },
    "dr. shivani efc": {
      name: "Dr. Shivani",
      link: "https://calendly.com/drshivani-arva/egg-freezing-consultation",
    },
  }
);
let calendlyDataobj = {
  arvaonplum100_dc01: calendlyUrlByPerson["dr. shivani fhc"],
  arvaonplum100_dc02: calendlyUrlByPerson["dr. shivani efc"],
  arvaongx20_dc01: calendlyUrlByPerson["dr. shivani fhc"],
  arvaongx20_dc02: calendlyUrlByPerson["dr. shivani efc"],
  karnataka: calendlyUrlByPerson["rishina bansal"],
  maharashtra: calendlyUrlByPerson["dr. aditi tandon"],
  dc02: calendlyUrlByPerson["rishina bansal"],
};
export const getCalendlyUrl = (txt, state, sku) => {
  return (
    calendlyDataobj[txt?.trim().toLowerCase()] ||
    calendlyDataobj[state?.trim()?.toLowerCase()] ||
    calendlyDataobj[sku?.trim()?.toLowerCase()] ||
    calendlyUrlByPerson["dr. aditi tandon"]
  );
};
