export const svg = {
  logo: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="81"
          height="20"
          viewBox="0 0 81 20"
          fill="none"
        >
          <path
            d="M0.6701 19.83H3.67293C3.87123 19.83 4.09786 19.7167 4.18285 19.4901L5.85424 15.2125H15.401L17.0724 19.4901C17.1574 19.7167 17.384 19.83 17.5823 19.83H20.5851C21.0667 19.83 21.4066 19.4051 21.2083 18.9235L13.8996 0.339944C13.8146 0.113315 13.5879 0 13.3896 0H7.86557C7.66727 0 7.44064 0.113315 7.35565 0.339944L0.046871 18.9235C-0.123101 19.3768 0.188514 19.83 0.6701 19.83ZM7.32732 11.5297L10.6418 2.91785L13.9845 11.5297H7.32732Z"
            fill="var(--svg_color,#5F1D1B)"
          />
          <path
            d="M39.6717 20C40.1533 20 40.5216 19.915 40.5216 19.1501V16.8839C40.5216 16.4589 40.4083 16.3173 39.955 16.289C38.5669 16.2323 38.5103 13.5694 36.6972 11.898C38.4819 10.7932 39.4734 8.95184 39.4734 6.60057C39.4734 2.63456 36.6689 0 32.1647 0H23.5244C23.2695 0 23.0995 0.141643 23.0995 0.424929V19.4051C23.0995 19.6601 23.2695 19.83 23.5244 19.83H26.6406C26.9239 19.83 27.0655 19.6601 27.0655 19.4051V13.2011H31.4564C35.7907 13.2011 33.8927 20 39.6717 20ZM27.0655 9.51841V3.68272H32.3063C34.2893 3.68272 35.5074 4.70255 35.5074 6.60057C35.5074 8.49858 34.2893 9.51841 32.3063 9.51841H27.0655Z"
            fill="var(--svg_color,#5F1D1B)"
          />
          <path
            d="M49.0569 19.83H52.8529C53.0512 19.83 53.2779 19.7167 53.3629 19.4901L61.2382 0.906516C61.4365 0.424929 61.0966 0 60.615 0H57.4139C57.2156 0 56.9889 0.113315 56.9039 0.339944L50.9549 15.2691L45.0059 0.339944C44.9209 0.113315 44.6943 0 44.496 0H41.2949C40.8133 0 40.4733 0.424929 40.6716 0.906516L48.547 19.4901C48.632 19.7167 48.8586 19.83 49.0569 19.83Z"
            fill="var(--svg_color,#5F1D1B)"
          />
          <path
            d="M59.5562 19.83H62.559C62.7573 19.83 62.984 19.7167 63.0689 19.4901L64.7403 15.2125H74.2871L75.9585 19.4901C76.0434 19.7167 76.2701 19.83 76.4684 19.83H79.4712C79.9528 19.83 80.2927 19.4051 80.0944 18.9235L72.7857 0.339944C72.7007 0.113315 72.474 0 72.2757 0H66.7517C66.5534 0 66.3267 0.113315 66.2418 0.339944L58.933 18.9235C58.763 19.3768 59.0746 19.83 59.5562 19.83ZM66.2134 11.5297L69.5279 2.91785L72.8706 11.5297H66.2134Z"
            fill="var(--svg_color,#5F1D1B)"
          />
        </svg>
      </div>
    );
  },
  arrow: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="var(--svg_color,#5F1D1B)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
    );
  },
  search: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12C5.791 12 4 10.209 4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12ZM17.707 16.293L12.887 11.473C13.585 10.492 14 9.296 14 8C14 4.687 11.313 2 8 2C4.687 2 2 4.687 2 8C2 11.313 4.687 14 8 14C9.296 14 10.492 13.585 11.473 12.887L16.293 17.707C16.488 17.902 16.744 18 17 18C17.256 18 17.512 17.902 17.707 17.707C18.098 17.316 18.098 16.684 17.707 16.293Z"
            fill="var(--svg_color,#5F1D1B)"
          />
        </svg>
      </div>
    );
  },
  tick: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.72318 0.237414C10.0707 0.574491 10.0942 1.14584 9.77561 1.51356L4.40976 7.70711C4.25243 7.88871 4.03181 7.99443 3.79903 7.99979C3.56625 8.00514 3.3415 7.90965 3.17686 7.73545L0.250031 4.63868C-0.0833435 4.28595 -0.0833435 3.71406 0.250031 3.36133C0.583405 3.00859 1.12391 3.00859 1.45729 3.36133L3.75367 5.79105L8.51706 0.292899C8.83564 -0.0748211 9.37564 -0.0996624 9.72318 0.237414Z"
            fill="var(--svg_color,#5F1D1B)"
          ></path>
        </svg>
      </div>
    );
  },
  range: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M13.334 6.66683V13.3335M10.0007 9.16683V13.3335M6.66732 11.6668V13.3335M5.00065 16.6668H15.0007C15.4427 16.6668 15.8666 16.4912 16.1792 16.1787C16.4917 15.8661 16.6673 15.4422 16.6673 15.0002V5.00016C16.6673 4.55814 16.4917 4.13421 16.1792 3.82165C15.8666 3.50909 15.4427 3.3335 15.0007 3.3335H5.00065C4.55862 3.3335 4.1347 3.50909 3.82214 3.82165C3.50958 4.13421 3.33398 4.55814 3.33398 5.00016V15.0002C3.33398 15.4422 3.50958 15.8661 3.82214 16.1787C4.1347 16.4912 4.55862 16.6668 5.00065 16.6668Z"
            stroke="var(--svg_color,#5F1D1B)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
    );
  },
  sun: (cust_class = "", click = "") => {
    return (
      <div
        className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
        onClick={() => (click ? click() : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
            stroke="var(--svg_color,#5F1D1B)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>{" "}
      </div>
    );
  },
};

// export const svg = (type, cust_class = "", click) => (
//   <div
//     className={"svg_group" + (cust_class ? ` ${cust_class}` : "")}
//     onClick={() => (click ? click() : "")}
//   >
//     {svg_main[type] || "NA"}
//   </div>
// );
