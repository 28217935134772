import React from "react";
import DashboardNavigation from "./DashboardNavigation/DashboardNavigation";
import { useSelector } from "react-redux";

const Dashboard = ({ children }) => {
  const store = useSelector((state) => state.appState);

  return (
    <div className="dashboard_main">
      {!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase()) ? (
        <DashboardNavigation />
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default Dashboard;
