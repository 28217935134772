import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustDropdown from "../../../../components/CustInput/CustDropdown";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustTable from "../../../../components/Table/CustTable";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers, getProducts } from "../../../../services/api";
import { formatPhone, sanityimage } from "../../../../utils/utils";

const Products = () => {
  const {
    user: { token },
    products,
    allcustomers,
  } = useSelector((state) => state.appState);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");
  const [filters, setfilters] = useState({
    date: ["all"],
    product_id: ["all"],
    status: ["all"],
    priority: ["all"],
    users: ["all"],
  });
  const [filter_sets, setfilter_sets] = useState({
    products: [
      {
        key: "test",
        value: "Tests",
      },
      {
        key: "coaching",
        value: "Coaching",
      },
    ],
    bundles: [],
  });
  const [order_search, setorder_search] = useState("");
  const [list, setlist] = useState([
    // {
    //   customerName: "Alice",
    //   phone: "1234567890",
    //   email: "alice@example.com",
    //   date: "05/30/2024",
    //   goal: "Learn JavaScript",
    // },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (products && Array.isArray(products)) {
      // setlist(products);
    }
    getProducts(
      (response) => {
        let products = [];
        let products_by_id = {};
        let bundles = [];
        response.data.explore.map((el) => {
          const { sku, servicesIncluded, _id } = el;
          if (servicesIncluded) {
            bundles.push({ ...el });
          } else {
            products_by_id[el._id] = { ...el };
            products.push({ ...el });
          }
        });
        bundles = bundles.map((el) => ({
          ...el,
          servicesIncluded: el.servicesIncluded.map((el1) => ({
            ...products_by_id[el1._ref],
          })),
        }));
        setlist(products);
        dispatch({
          type: "setAppState",
          subType: "products",
          payload: {
            products,
            bundles,
            api: {
              is_products: true,
              is_bundles: true,
            },
          },
        });
      },
      (error) => {
        //
      }
    );
    return () => {};
  }, []);
  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  return (
    <div className="dashboard_body products_page orders_page">
      <div className="dashboard_subheader">
        <div className="title">Products</div>
        <div className="search_and_filter">
          <CustDropdown
            {...{
              ...dropdown_settings,
              multiselect: true,
              all: true,
              // default_item: "ft01",
              title: "Type",
              value: filters.priority,
              setvalue: (value) =>
                setfilters((prev) => ({
                  ...prev,
                  priority: value,
                })),
              list: [
                {
                  key: "test",
                  value: "Tests",
                },
                {
                  key: "coaching",
                  value: "Coaching",
                },
                {
                  key: "consult",
                  value: "Consult",
                },
                {
                  key: "guide",
                  value: "Guide",
                },
              ],
              list_show: (data) => data.value,
            }}
          />
          <CustSearch
            {...{
              value: order_search,
              setvalue: setorder_search,
            }}
          />
        </div>
      </div>
      <div className="dashboard_subbody">
        <div className="products_table">
          <CustTable
            {...{
              data: list
                ?.filter((el) => {
                  let search = order_search
                    ? el.name.toLowerCase().includes(order_search.toLowerCase())
                    : true;
                  let type = filters.priority.includes("all")
                    ? true
                    : filters.priority.includes(el.type);
                  return search && type;
                })
                .map((el) => {
                  let img = sanityimage(el?.image || {});
                  return {
                    ...el,
                    img: (
                      <div
                        className="product_image"
                        style={{ backgroundImage: `url("${img || ""}")` }}
                      ></div>
                    ),
                    rowClick: () =>
                      window.open(
                        `https://arvacontent.sanity.studio/desk/servicesV2;${el._id}`,
                        "_blank"
                      ),
                  };
                }),
              nosrnogap: true,
              header: [
                {
                  id: "img",
                  title: "Image",
                },
                {
                  id: "sku",
                  title: "SKU",
                },
                {
                  id: "name",
                  title: "Namer",
                },
                {
                  id: "type",
                  title: "Type",
                },
                {
                  id: "price",
                  title: "Price",
                },
              ],
              onrowclick: (row_data) => {},
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Products;
