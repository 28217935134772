import React, { useLayoutEffect, useState } from "react";
// import CustInputMuiClone from "../CustInput/CustInputMuiClone";
// import { dateFormatter } from "../CustInput/CustInput";
import CustInputMuiClone from "../../../../../components/CustInput/CustInputMuiClone";
import { dateFormatter } from "../../../../../utils/utils";
import { svg } from "../../../../../utils/svg";

export const LevelListForAdmin = ({
  editLevel,
  titleDate,
  title,
  list,
  setlistupdate,
  listupdate,
  to_show,
  edit,
  setresult_values,
  setcurrent_open_level,
  current_open_level,
  //   setheight,
  //   height,
}) => {
  const [height, setheight] = useState([]);
  const [current_ques, setcurrent_ques] = useState("");
  useLayoutEffect(() => {
    // let temp_height = list?.map((el, i) => {
    //   return document
    //     ?.getElementById(`our_test_descriptionadmin_${i + 1}`)
    //     ?.getBoundingClientRect().height;
    // });
    // setheight(temp_height);
  }, [list]);

  //   const updateCount = (index, newCount, el) => {
  //     const updatedPageValues = JSON.parse(JSON.stringify([...listupdate]));
  //     updatedPageValues[index].count = newCount;
  //     setlistupdate(updatedPageValues);
  //   };
  //   window.addEventListener("click", function () {
  //     setcurrent_ques(false);
  //   });

  let ListBMI = [
    {
      name: "Underweight",
      range: "<18.5",
      color: "#F05E45",
      bgcolor: "#FDEFEC",
    },
    {
      name: "Normal ",
      range: "18.5–25",
      color: "#64BE45",
      bgcolor: "#EFF8EC",
    },
    {
      name: "Overweight",
      range: ">25",
      color: "#B418D1",
      bgcolor: "#F8E8FA",
    },
  ];

  let BMIRange = (value) => {
    let num = value;

    if (!Number(num)) {
      num = Number(value);
    }
    //
    let filteredData;

    if (num >= 20) {
      filteredData = ListBMI.slice(-3); // Take the last 3 objects
    } else {
      filteredData = ListBMI.slice(0, 3); // Take the first 3 objects
    }
    return filteredData;
  };

  let BMIColor = (value) => {
    // const rangeValue = 12.0;
    let rangeValue = value;

    if (!Number(rangeValue)) {
      rangeValue = Number(value ? value : 0);
    }

    const filteredListBMI = ListBMI.filter((item) => {
      const range = item.range;
      if (range.startsWith("<")) {
        const upperLimit = parseFloat(range.substring(1));
        return rangeValue < upperLimit;
      } else if (range.startsWith(">")) {
        const lowerLimit = parseFloat(range.substring(1));
        return rangeValue >= lowerLimit;
      } else if (range.includes("–")) {
        const [lower, upper] = range.split("–").map(parseFloat);
        return rangeValue >= lower && rangeValue <= upper;
      } else {
        // If the range format is not recognized, you can handle it accordingly
        return false;
      }
    });

    return filteredListBMI;
  };

  //   switch (editLevel) {
  // case true:
  //   return (
  //     <>
  //       <div className="main_levelList">
  //         <div className="main_levelList_top">
  //           <div className="main_levelList_title">{title}</div>
  //           {titleDate && (
  //             <div className="main_levelList_date">
  //               {dateFormatter(titleDate, "date / month / year_long")}
  //             </div>
  //           )}
  //         </div>
  //         <div>
  //           <div className="mainlevel_list_data">
  //             {list?.map((el, i) => (
  //               <div key={i} className="mainlevel_list_data_box">
  //                 <div
  //                   className="mainlevel_list_data_box_vis"
  //                   onClick={(e) => {
  //                     setcurrent_ques((prev) =>
  //                       prev === i + 1 ? "" : i + 1
  //                     );
  //                     e.preventDefault();
  //                     e.stopPropagation();
  //                   }}
  //                 >
  //                   <div className="mainlevel_list_data_box_left">
  //                     <div className="mainlevel_list_data_box_left_title">
  //                       {el.name}
  //                     </div>
  //                     <div className="mainlevel_list_data_box_left_desc">
  //                       {el.desc}
  //                     </div>
  //                   </div>
  //                   <div
  //                     className="mainlevel_list_data_box_right_input"
  //                     style={{ color: el.color }}
  //                   >
  //                     <CustInputMuiClone
  //                       {...{
  //                         attr: {
  //                           type: "number",
  //                           error_msg: "",
  //                           value: el.count,
  //                           inputMode: "numeric",
  //                           event: {
  //                             change: ({ target }) => {
  //                               updateCount(i, target.value, el);
  //                             },
  //                           },
  //                         },
  //                         data: {
  //                           title: "",
  //                           suffix: el?.unit,
  //                         },
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );

  // default:

  return (
    <>
      {to_show?.map(
        ({
          name,
          short_name,
          desc,
          value_desc,
          value,
          color,
          unit,
          score,
          range,
          biomarkerCode,
        }) => (
          <div
            className="biomarker_main"
            onClick={() => setcurrent_open_level(short_name)}
          >
            <div className="flex main">
              <div className="short_name">{short_name}</div>
              <div className="value_txt" color={color}>
                {score}
              </div>
            </div>
            <div className="flex">
              {!edit || short_name.toLowerCase() == "bmi" ? (
                <>
                  <div className="name">{name}</div>
                  <div className="value" color={color}>
                    {`${value || 0} ${range?.unit}`}
                  </div>
                </>
              ) : (
                <CustInputMuiClone
                  {...{
                    unique_id: short_name,
                    attr: {
                      error: "",
                      error_msg: "",
                      value: value,
                      inputMode: "text",
                      event: {
                        change: ({ target }) => {
                          let inp = target.value.trim();
                          if (isNaN(Number(inp))) {
                            return;
                          }
                          setresult_values((prev) => {
                            let t = { ...prev };
                            if (t[biomarkerCode]) {
                              t[biomarkerCode] = {
                                ...t[biomarkerCode],
                                value: inp,
                              };
                            } else {
                              t[biomarkerCode] = { value: inp };
                            }
                            return t;
                          });
                        },
                        onkeydown: (e) => {},
                      },
                    },
                    data: {
                      placeholder: "",
                      suffix: range?.unit,
                    },
                  }}
                />
              )}
            </div>
            {short_name === current_open_level ? (
              <>
                <div className="gap-16"></div>
                {range?.max || range?.max === 0 ? (
                  <>
                    <div className="range_header">{svg.range()} RANGE </div>
                    <div className="ranges_group">
                      <div
                        color="0"
                        className={score === "low" ? "active" : ""}
                      >
                        <div>Low</div>
                        <div>
                          {range?.start} -{range?.min}
                        </div>
                      </div>
                      <div
                        color="1"
                        className={score === "optimal" ? "active" : ""}
                      >
                        <div>Optimal</div>
                        <div>
                          {range?.min} -{range?.max}
                        </div>
                      </div>
                      <div
                        color="2"
                        className={score === "high" ? "active" : ""}
                      >
                        <div>High</div>
                        <div>
                          {range?.max} -{range?.end}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {value_desc ? (
                  <>
                    <div className="range_header">
                      {svg.sun()}
                      WHAT THIS MEANS
                    </div>
                    <div className="description value_desc">
                      <div className="about">{value_desc || ""}</div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {desc ? (
                  <>
                    <div className="range_header">
                      {svg.sun()}
                      WHAT THIS MEANS
                    </div>
                    <div className="description srtatic_desc">
                      <div className="about">{desc || ""}</div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )
      )}
    </>
  );
};
// };
