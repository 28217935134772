import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustDropdown from "../../../../components/CustInput/CustDropdown";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustTable from "../../../../components/Table/CustTable";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../../services/api";
import { dateFormatter, formatPhone } from "../../../../utils/utils";

const Customers = () => {
  const {
    user: { token },
    products,
    allcustomers,
  } = useSelector((state) => state.appState);
  const table_div = useRef();
  const [sortConfig, setSortConfig] = useState({
    customerName: "",
    phone: "",
    email: "",
    date: "",
    goal: "",
    // { key: 'customer', direction: 'ascending' },
    // { key: 'phoneNumber', direction: 'descending' },
  });
  const [filters, setfilters] = useState({
    goal: ["all"],
    city: ["all"],
    age: ["all"],
    state: ["all"],
    // sort: ["all"],
  });
  const [intialfilters, setintialfilters] = useState({
    goal: [],
    city: [],
    age: [],
    state: [],
  });

  const [order_search, setorder_search] = useState("");
  const [usersToShow, setusersToShow] = useState([]);
  const [users, setusers] = useState([
    // {
    //   customerName: "Alice",
    //   phone: "1234567890",
    //   email: "alice@example.com",
    //   date: "05/30/2024",
    //   goal: "Learn JavaScript",
    // },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("search")) {
      setorder_search(searchParams.get("search"));
    }
    let t_filters = { ...filters };
    ["goal", "city", "age"].map((el) => {
      if (searchParams.get(el)) {
        t_filters[el] = JSON.parse(searchParams.get(el));
      }
    });
    setfilters(t_filters);
  }, []);
  useEffect(() => {
    if (allcustomers) {
      setusers(allcustomers);
    }
    getCustomers(
      token,
      (success) => {
        if (success) {
          //
          let t_goal = [];
          let t_city = [];
          let t_age = [];
          let t_state = [];
          let users = success.map(
            ({
              _id,
              first_name,
              last_name,
              prefix,
              phone,
              email,
              goal,
              lastOrderDate,
              city,
              age,
              formatDate,
              state,
            }) => {
              if (t_goal.indexOf(goal) == -1) {
                t_goal.push(goal);
              }
              if (t_city.indexOf(city) == -1) {
                t_city.push(city);
              }
              if (t_age.indexOf(age) == -1) {
                t_age.push(age);
              }
              if (t_state.indexOf(state) == -1) {
                t_state.push(state);
              }
              return {
                customerName: `${first_name} ${last_name}`,
                phone: formatPhone(phone, prefix),
                unformatPhone: phone,
                email,
                date: lastOrderDate,
                formatDate,
                state,
                // sortDate,
                // sortdate: [lastOrderDate.split("/")].reduce(
                //   (acc, curr) => `${curr[1]}/${curr[0]}/${curr[2]}`,
                //   ""
                // ),
                goal,
                city,
                age,
                id: _id,
              };
            }
          );
          setintialfilters({
            goal: t_goal.filter((el) => el),
            city: t_city.filter((el) => el),
            age: t_age.filter((el) => el),
            state: t_state.filter((el) => el),
          });
          setusers(users);
          dispatch({
            type: "setAppState",
            subType: "allcustomers",
            payload: [...users],
          });
        }
      },
      (err) => {}
    );

    return () => {};
  }, []);
  const [pagination, setpagination] = useState({
    start: 0,
    diff: 100,
  });
  useEffect(() => {
    let filteredUsers = users
      // ?.filter(
      //   (el) =>
      //     el.customerName
      //       .toLowerCase()
      //       .includes(order_search.trim().toLowerCase()) ||
      //     el.unformatPhone.match(order_search.replaceAll(" ", "").trim())
      // )
      .filter((el) => {
        let data = [
          el?.customerName || "",
          el?.unformatPhone || "",
          el?.email || "",
        ];
        let search = order_search
          ? JSON.stringify(data)
              .toLowerCase()
              .includes(order_search.trim().toLowerCase())
          : true;
        let goal = !filters?.goal?.includes("all")
          ? filters?.goal?.includes(el?.goal || "NA")
          : true;
        let city = !filters?.city?.includes("all")
          ? filters?.city.includes(el?.city || "NA")
          : true;
        let state = !filters?.state?.includes("all")
          ? filters?.state.includes(el?.state || "NA")
          : true;
        let age = !filters?.age?.includes("all")
          ? filters?.age?.includes(el?.age || "NA")
          : true;
        return search && goal && city && state && age;
      })
      .sort((a, b) => {
        // for (let config of ["customerName", "phone", "email", "date", "goal"]) {
        let config = sortConfig.customerName
          ? "customerName"
          : sortConfig.phone
          ? "phone"
          : sortConfig.email
          ? "email"
          : sortConfig.date
          ? "date"
          : sortConfig.goal
          ? "goal"
          : "";
        // console.log(config);

        if (!config) {
          return 0;
        }
        const aValue = a[config];
        const bValue = b[config];
        // console.log(aValue, bValue, config, typeof aValue, typeof bValue);

        // if (typeof aValue === "string" && typeof bValue === "string") {
        if (["customerName", "phone", "email", "goal"].includes(config)) {
          const result = aValue?.localeCompare(bValue, undefined, {
            sensitivity: "base",
          });
          if (result !== 0) {
            return (
              {
                ascending: result,
                descending: -result,
              }[sortConfig?.[config] || ""] || 0
            );
          }
        } else {
          if (new Date(aValue).valueOf() < new Date(bValue).valueOf()) {
            return (
              {
                ascending: -1,
                descending: 1,
              }[sortConfig?.[config] || ""] || 0
            );
          }
          if (new Date(aValue).valueOf() > new Date(bValue).valueOf()) {
            return (
              {
                ascending: 1,
                descending: -1,
              }[sortConfig?.[config] || ""] || 0
            );
          }
        }
        //  else {
        //   if (aValue < bValue) {
        //     return (
        //       {
        //         ascending: -1,
        //         descending: 1,
        //       }[sortConfig?.[config] || ""] || 0
        //     );
        //   }
        //   if (aValue > bValue) {
        //     return (
        //       {
        //         ascending: 1,
        //         descending: -1,
        //       }[sortConfig?.[config] || ""] || 0
        //     );
        //   }
        // }
        // }
        return 0;
      });
    setpagination((prev) => ({
      ...prev,
      start: 0,
    }));
    setusersToShow(filteredUsers);
  }, [users, order_search, filters, sortConfig]);
  useLayoutEffect(() => {
    setpagination({
      start: 0,
      diff: Math.floor(
        (table_div.current.getBoundingClientRect().height - 55) / 57
      ),
    });
  }, []);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");

  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };

  const setFilterSerach = (key, value) => {
    let url = new URL(window.location.href);
    url.searchParams.set(key, JSON.stringify(value));
    // ["product_id", "status", "priority", "users"].map((el) =>
    // );
    window.history.pushState({}, "", url);
  };
  console.log(sortConfig);

  return (
    <div className="dashboard_body customer_page orders_page">
      <div className="dashboard_subheader">
        <div className="title">
          Customers{usersToShow.length ? ` - ${usersToShow.length}` : ""}
        </div>

        <div className="search_and_filter">
          <CustSearch
            {...{
              value: order_search,
              setvalue: (value) => {
                setorder_search(value);
                let url = new URL(window.location.href);
                url.searchParams.set("search", value || "");
                window.history.pushState({}, "", url);
              },
            }}
          />
          <CustDropdown
            {...{
              ...dropdown_settings,
              multiselect: true,
              all: true,
              title: "Goal",
              value: filters.goal,
              setvalue: (value) => {
                setfilters((prev) => ({
                  ...prev,
                  goal: value,
                }));
                setFilterSerach("goal", value);
              },
              list: [...intialfilters.goal].map((el) => ({
                key: el,
                value: el,
              })),
              list_show: (data) => data.value,
            }}
          />
          <CustDropdown
            {...{
              ...dropdown_settings,
              multiselect: true,
              all: true,
              title: "City",
              value: filters.city,
              setvalue: (value) => {
                setfilters((prev) => ({
                  ...prev,
                  city: value,
                }));
                setFilterSerach("city", value);
              },
              list: [...intialfilters.city].map((el) => ({
                key: el,
                value: el,
              })),
              list_show: (data) => data.value,
            }}
          />
          <CustDropdown
            {...{
              ...dropdown_settings,
              multiselect: true,
              all: true,
              title: "State",
              value: filters.state,
              setvalue: (value) => {
                setfilters((prev) => ({
                  ...prev,
                  state: value,
                }));
                setFilterSerach("state", value);
              },
              list: [...intialfilters.state].map((el) => ({
                key: el,
                value: el,
              })),
              list_show: (data) => data.value,
            }}
          />
          <CustDropdown
            {...{
              ...dropdown_settings,
              multiselect: true,
              all: true,
              title: "Age",
              value: filters.age,
              setvalue: (value) => {
                setfilters((prev) => ({
                  ...prev,
                  age: value,
                }));
                setFilterSerach("age", value);
              },
              list: [...intialfilters.age].sort().map((el) => ({
                key: el,
                value: el,
              })),
              list_show: (data) => data.value,
            }}
          />
          {/* <CustDropdown
            {...{
              ...dropdown_settings,
              // multiselect: true,
              // all: true,
              title: "Sort",
              value: filters.sort,
              setvalue: (value) => {
                setfilters((prev) => ({
                  ...prev,
                  sort: value,
                }));
                value.map((el) => {
                  let [sortkey, sortvalue] = el.split("-");
                  setSortConfig((prev) => ({
                    // ...prev,
                    customerName: "",
                    phone: "",
                    email: "",
                    date: "",
                    goal: "",
                    [sortkey]:
                      { a: "ascending", d: "descending" }?.[sortvalue] || "",
                  }));
                });
                setFilterSerach("sort", value);
              },
              list: [
                {
                  ascending: {
                    key: "customerName-d",
                    value: "customerName A->D",
                  },
                  descending: {
                    key: "customerName-",
                    value: "customerName D->N",
                  },
                }?.[sortConfig?.customerName] || {
                  key: "customerName-a",
                  value: "customerName N->A",
                },
                {
                  ascending: {
                    key: "phone-d",
                    value: "Phone A->D",
                  },
                  descending: {
                    key: "phone-",
                    value: "Phone D->N",
                  },
                }?.[sortConfig?.phone] || {
                  key: "phone-a",
                  value: "Phone N->A",
                },
                {
                  ascending: {
                    key: "email-d",
                    value: "Email A->D",
                  },
                  descending: {
                    key: "email-",
                    value: "Email D->N",
                  },
                }?.[sortConfig?.email] || {
                  key: "email-a",
                  value: "Email N->A",
                },
                {
                  ascending: {
                    key: "date-d",
                    value: "Date A->D",
                  },
                  descending: {
                    key: "date-",
                    value: "Date D->N",
                  },
                }?.[sortConfig?.date] || {
                  key: "date-a",
                  value: "Date N->A",
                },
                {
                  ascending: {
                    key: "goal-d",
                    value: "Goal A->D",
                  },
                  descending: {
                    key: "goal-",
                    value: "Goal D->N",
                  },
                }?.[sortConfig?.goal] || {
                  key: "goal-a",
                  value: "Goal N->A",
                },
              ],
              list_show: (data) => data.value,
            }}
          /> */}
          <div
            className="btn dark_btn"
            onClick={() => {
              let url = new URL(window.location.href);
              ["goal", "city", "age", "search"].map((el) =>
                url.searchParams.delete(el)
              );
              window.history.pushState({}, "", url);
              setfilters((prev) => ({
                ...prev,
                goal: ["all"],
                city: ["all"],
                age: ["all"],
                search: ["all"],
              }));
              setorder_search("");
            }}
          >
            Clear all
          </div>
        </div>
      </div>
      <div className="dashboard_subbody">
        <div className="customer_table" ref={table_div}>
          <CustTable
            {...{
              data: usersToShow

                .slice(pagination.start, pagination.start + pagination.diff)
                .map((el, i) => ({
                  ...el,
                  date: el.formatDate,
                  // date: dateFormatter(el.date, "DD/MM/YYYY"),
                  rowClick: () => navigate(`../${el.id}/overview`),
                })),
              nosrnogap: true,
              header: [
                {
                  id: "customerName",
                  title: "Customer",
                },
                {
                  id: "phone",
                  title: "Phone Number",
                },
                {
                  id: "email",
                  title: "Email",
                },
                {
                  id: "date",
                  title: "Last Order Date",
                },
                {
                  id: "goal",
                  title: "Goal",
                },
              ].map((el) => ({
                ...el,
                title: (
                  <div
                    className="sorting_table_header"
                    onClick={() => {
                      console.log(el.id, sortConfig[el.id]);

                      setSortConfig((prev) => ({
                        // ...prev,
                        customerName: "",
                        phone: "",
                        email: "",
                        date: "",
                        goal: "",
                        [el.id]:
                          { ascending: "normal", descending: "ascending" }?.[
                            prev?.[el.id]
                          ] || "descending",
                      }));
                    }}
                  >
                    <div>{el.id}</div>

                    <div
                      className="arrow_group"
                      type={
                        {
                          ascending: "a",
                          descending: "d",
                        }?.[sortConfig?.[el.id]] || "n"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="177"
                        height="369"
                        viewBox="0 0 177 369"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.3503 264.026C11.8503 259.532 17.9503 257.007 24.3103 257.007C30.6703 257.007 36.7703 259.532 41.2703 264.026L88.3103 311.034L135.35 263.994C139.879 259.624 145.942 257.208 152.235 257.266C158.528 257.323 164.546 259.85 168.994 264.302C173.442 268.754 175.963 274.775 176.015 281.067C176.066 287.36 173.644 293.422 169.27 297.946L105.27 361.946C100.77 366.44 94.6703 368.965 88.3103 368.965C81.9503 368.965 75.8503 366.44 71.3503 361.946L7.3503 297.946C5.12007 295.717 3.35087 293.071 2.14379 290.158C0.936716 287.245 0.31543 284.123 0.31543 280.97C0.31543 277.817 0.936716 274.695 2.14379 271.782C3.35087 268.869 5.12007 266.223 7.3503 263.994V264.026Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M88.3105 0.985962C94.6757 0.985962 100.78 3.51452 105.281 8.0154C109.782 12.5163 112.311 18.6208 112.311 24.986V344.986C112.311 351.351 109.782 357.456 105.281 361.957C100.78 366.457 94.6757 368.986 88.3105 368.986C81.9454 368.986 75.8409 366.457 71.34 361.957C66.8391 357.456 64.3105 351.351 64.3105 344.986V24.986C64.3105 18.6208 66.8391 12.5163 71.34 8.0154C75.8409 3.51452 81.9454 0.985962 88.3105 0.985962Z"
                          fill="black"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="177"
                        height="369"
                        viewBox="0 0 177 369"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.3503 264.026C11.8503 259.532 17.9503 257.007 24.3103 257.007C30.6703 257.007 36.7703 259.532 41.2703 264.026L88.3103 311.034L135.35 263.994C139.879 259.624 145.942 257.208 152.235 257.266C158.528 257.323 164.546 259.85 168.994 264.302C173.442 268.754 175.963 274.775 176.015 281.067C176.066 287.36 173.644 293.422 169.27 297.946L105.27 361.946C100.77 366.44 94.6703 368.965 88.3103 368.965C81.9503 368.965 75.8503 366.44 71.3503 361.946L7.3503 297.946C5.12007 295.717 3.35087 293.071 2.14379 290.158C0.936716 287.245 0.31543 284.123 0.31543 280.97C0.31543 277.817 0.936716 274.695 2.14379 271.782C3.35087 268.869 5.12007 266.223 7.3503 263.994V264.026Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M88.3105 0.985962C94.6757 0.985962 100.78 3.51452 105.281 8.0154C109.782 12.5163 112.311 18.6208 112.311 24.986V344.986C112.311 351.351 109.782 357.456 105.281 361.957C100.78 366.457 94.6757 368.986 88.3105 368.986C81.9454 368.986 75.8409 366.457 71.34 361.957C66.8391 357.456 64.3105 351.351 64.3105 344.986V24.986C64.3105 18.6208 66.8391 12.5163 71.34 8.0154C75.8409 3.51452 81.9454 0.985962 88.3105 0.985962Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                ),
              })),
              onrowclick: (row_data) => {},
            }}
          />
        </div>
        <div className="pagination_customer">
          <div
            className="prev"
            onClick={() => {
              setpagination((prev) => ({
                ...prev,
                start: prev.start - prev.diff <= 0 ? 0 : prev.start - prev.diff,
              }));
            }}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="var(--svg_color,#5F1D1B)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div>
            {pagination.start + 1} -{" "}
            {pagination.start + pagination.diff > usersToShow.length
              ? usersToShow.length
              : pagination.start + pagination.diff}
          </div>
          <div
            className="next"
            onClick={() => {
              setpagination((prev) => ({
                ...prev,
                start:
                  prev.start + prev.diff >= usersToShow.length
                    ? prev.start
                    : prev.start + prev.diff,
              }));
            }}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="var(--svg_color,#5F1D1B)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
