import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./store/store";

// import "react-phone-input-2/lib/style.css";

import Layout from "./Layout";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Layout />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
