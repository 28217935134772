import React, { useLayoutEffect, useState } from "react";
import { svg } from "../../utils/svg";

const CustDropdown = ({
  is_open,
  setdropdown,
  multiselect,
  all,
  default_item,
  title,
  value,
  setvalue,
  list,
  list_show,
  dropdown_align,
  hide_title,
}) => {
  useLayoutEffect(() => {
    window.onclick = () => {
      setdropdown("");
    };
  }, []);
  return (
    <div className="cust_dropdown" onClick={(e) => e.stopPropagation()}>
      <div
        className="to_show"
        onClick={() => setdropdown((prev) => (prev == title ? "" : title))}
      >
        {/* <div className="txt">{list_show ? list_show(value) : value}</div> */}
        {!hide_title ? (
          <div className="txt">{title || ""}</div>
        ) : (
          <div className="txt">{value || ""}</div>
        )}
        {svg.arrow()}
      </div>
      {is_open == title ? (
        <div
          className="options_main"
          // active={is_open == title ? "true" : "false"}
          align={dropdown_align || "right_align"}
        >
          <div className="options">
            {all ? (
              <div
                className="items"
                onClick={() => {
                  setvalue(["all"]);
                  // setis_open(false);
                  setdropdown("");
                }}
              >
                <div
                  className="cust_checkbox"
                  active={value.includes("all") ? "true" : ""}
                >
                  {svg.tick()}
                </div>
                {/* <input type="checkbox" checked={value.includes("all")} /> */}
                All
              </div>
            ) : (
              ""
            )}
            {list?.map((el, i) => (
              <div
                className="items"
                onClick={() => {
                  if (multiselect) {
                    let new_product_id = [...value];
                    if (default_item && default_item == el.key) {
                      new_product_id = [default_item];
                    } else if (value.includes(el.key)) {
                      new_product_id = value.filter((el1) => el1 !== el.key);
                    } else {
                      new_product_id = [
                        ...value.filter((el1) =>
                          default_item ? el1 !== default_item : el1 !== "all"
                        ),
                        el.key,
                      ];
                    }
                    if (new_product_id.length == 0) {
                      new_product_id = default_item ? [default_item] : ["all"];
                    }
                    setvalue(new_product_id);
                  } else {
                    let current_value = value.includes(el.key)
                      ? ["all"]
                      : [el.key];
                    setvalue(current_value);
                    setdropdown("");
                  }

                  // setis_open(false);
                  // setdropdown("")
                }}
              >
                <div
                  className="cust_checkbox"
                  active={value.includes(el.key) ? "true" : ""}
                >
                  {svg.tick()}
                </div>
                {/* <input type="checkbox" checked={value.includes(el.key)} /> */}
                <div>{list_show ? list_show(el) : el.value}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustDropdown;
