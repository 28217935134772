import React, { useEffect, useLayoutEffect, useState } from "react";
import { dateFormatter, fileToBase64 } from "../../../../../../utils/utils";
import { click } from "@testing-library/user-event/dist/click";
import { svg } from "../../../../../../services/svg";
import {
  AddCustomerNotes,
  AddOrderNotes,
  createfiletolink,
  EditCustomerNotes,
  EditOrderNotes,
} from "../../../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import SlickSlider from "../../../../../../components/SlickSlider/SlickSlider";
const ActivityTracker = ({
  data,
  filter = ["Activity", "Notes", "Milestones"],
  order_id,
  token,
  type = "",
  current_page = "test",
}) => {
  const { testid, consultid, coachingid, page, subnav } = useParams();
  const params = useParams();

  const documentTypes = [
    ".pdf",
    ".doc",
    ".docx",
    ".txt",
    ".odt",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".plain",
    ".msword",
  ];
  const navigate = useNavigate();
  const [current_activity_filter, setcurrent_activity_filter] = useState(
    filter[0] || "Activity"
  );
  // useEffect(() => {
  //   setcurrent_activity_filter(filter[0]);
  // }, [filter]);

  useLayoutEffect(() => {
    if (subnav) {
      setcurrent_activity_filter(subnav);
    }
  }, [subnav, page]);

  const [curr_note_edit, setcurr_note_edit] = useState({
    id: "",
  });
  const [curr_notes, setcurr_notes] = useState("");
  const [curr_images_ids, setcurr_images_ids] = useState([
    // {
    //   // id:"new Date().valueOf()"
    // },
  ]);
  const [curr_prescription_ids, setcurr_prescription_ids] = useState([
    // {
    //   // id:"new Date().valueOf()"
    // },
  ]);
  const [currently_previewing, setcurrently_previewing] = useState([]);
  const [curr_images_data, setcurr_images_data] = useState({
    // id:{
    // name:"",
    // base64:""
    // link:""
    // }
  });
  const [curr_prescription_data, setcurr_prescription_data] = useState({
    // id:{
    // name:"",
    // base64:""
    // link:""
    // }
  });
  const [note_length, setnote_length] = useState(0);
  const [activity, setactivity] = useState([
    // {
    //   type: "InitiateOrder",
    //   createdAt: "Wed May 25 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   orderId: "0014",
    //   productName: "Complete Fertility Test",
    // },
    // {
    //   type: "Milestone",
    //   createdAt: "Wed May 25 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   txt: "Order Created",
    // },
    // {
    //   type: "Notification",
    //   createdAt: "Wed May 26 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   txt: "Test Scheduled message sent to the customer",
    // },
    // {
    //   type: "StatusUpdate",
    //   createdAt: "Wed May 26 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   prev: {
    //     status: "Test scheduled",
    //     priority: "Low",
    //   },
    //   curr: {
    //     status: "Test scheduled",
    //     priority: "Medium",
    //   },
    // },
    // {
    //   type: "StatusUpdate",
    //   createdAt: "Wed May 27 2024 06:40:50 GMT+0530 (India Standard Time)",
    //   prev: {
    //     status: "Test scheduled",
    //     priority: "Medium",
    //   },
    //   curr: {
    //     status: "Test scheduled",
    //     priority: "High",
    //   },
    // },
    // {
    //   type: "Note",
    //   createdAt: "Wed May 27 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   txt: "Customer called. Technician was 10m late. All resolved now.",
    //   images: [],
    //   createdBy: "shaily",
    // },
    // {
    //   type: "ScheduleUpdate",
    //   createdAt: "Wed May 28 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   status: "Test Scheduled",
    //   dateTime: "Wed June 03 2024 06:39:50 GMT+0530 (India Standard Time)",
    // },
    // {
    //   type: "AssignedTo",
    //   createdAt: "Wed May 29 2024 06:39:50 GMT+0530 (India Standard Time)",
    //   txt: "Maggie",
    // },
    // {
    //   type: "Note",
    //   createdAt: "Wed May 30 2024 06:39:50 GMT+0530 (India Standard Time)",
    // },
  ]);
  useEffect(() => {
    if (activity.length) {
      setnote_length(activity.filter(({ type }) => type == "Note").length || 0);
    }
  }, [activity]);
  useEffect(() => {
    let curr_createdAt = "";
    if (data.length) {
      setactivity(
        [
          ...data
            .sort(
              (a, b) =>
                new Date(a.activity.createdAt).valueOf() -
                new Date(b.activity.createdAt).valueOf()
            )
            .map((el) => {
              const {
                activity: { createdAt },
              } = el;
              let t_createdAt = new Date(createdAt);
              t_createdAt = new Date(
                String(t_createdAt).split(" ").slice(0, 4)
              );
              //
              if (!curr_createdAt) {
                curr_createdAt = t_createdAt;
                return el;
              } else {
                //
                if (
                  new Date(curr_createdAt).valueOf() !=
                  new Date(t_createdAt).valueOf()
                ) {
                  let with_date = [
                    {
                      activity: {
                        type: "Date",
                        createdAt: curr_createdAt,
                      },
                      orderInfo: {
                        orderId: "",
                      },
                    },
                    el,
                  ];
                  curr_createdAt = t_createdAt;
                  return with_date;
                } else {
                  curr_createdAt = t_createdAt;
                  return el;
                }
              }
            })
            .flat(),
          {
            activity: {
              type: "Date",
              createdAt: curr_createdAt,
            },
            orderInfo: {
              orderId: "",
            },
          },
        ].reverse()
      );
    }
  }, [data]);


  return (
    <div className="activity_tracker_group">
      <div className="header">
        {[
          {
            svg: svg.trendingup,
            name: "Activity",
            key: "Activity",
          },
          {
            svg: svg.documentOutline,
            name: (
              <>
                Notes
                {note_length > 0 ? (
                  <div className="count">{note_length}</div>
                ) : (
                  ""
                )}
              </>
            ),
            key: "Notes",
          },
          {
            svg: svg.star,
            name: "Milestones",
            key: "Milestones",
          },
        ].map(({ svg, name, key }) =>
          filter.includes(key) ? (
            <div
              className="header_items"
              active={
                key.toLowerCase() == current_activity_filter.toLowerCase()
                  ? "true"
                  : ""
              }
              onClick={() =>
                // navigate(
                //   `../${page}/${
                //     testid || consultid || coachingid
                //   }/${page}/${key.toLowerCase()}`
                // )
                navigate(
                  `../${current_page}/${
                    testid || consultid || coachingid
                  }/${page}/${key.toLowerCase()}`
                )
              }
            >
              {svg}
              {name}
            </div>
          ) : (
            ""
          )
        )}
      </div>
      <div className="body">
        {currently_previewing?.filter((el1) => !el1.endsWith(".pdf")).length >
        0 ? (
          <div className="preview_images">
            <div className="close" onClick={() => setcurrently_previewing([])}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8132 9.99999C17.8132 10.2486 17.7145 10.4871 17.5387 10.6629C17.3628 10.8387 17.1244 10.9375 16.8757 10.9375H5.39136L9.41636 14.9617C9.59248 15.1378 9.69143 15.3767 9.69143 15.6258C9.69143 15.8748 9.59248 16.1137 9.41636 16.2898C9.24024 16.466 9.00137 16.5649 8.7523 16.5649C8.50323 16.5649 8.26436 16.466 8.08824 16.2898L2.46324 10.6648C2.37584 10.5777 2.30649 10.4742 2.25917 10.3603C2.21186 10.2463 2.1875 10.1242 2.1875 10.0008C2.1875 9.87738 2.21186 9.75521 2.25917 9.64125C2.30649 9.5273 2.37584 9.42381 2.46324 9.33671L8.08824 3.71171C8.17544 3.6245 8.27897 3.55533 8.39291 3.50813C8.50685 3.46094 8.62897 3.43665 8.7523 3.43665C8.87563 3.43665 8.99775 3.46094 9.11169 3.50813C9.22563 3.55533 9.32916 3.6245 9.41636 3.71171C9.50357 3.79891 9.57275 3.90244 9.61994 4.01638C9.66714 4.13032 9.69143 4.25244 9.69143 4.37577C9.69143 4.4991 9.66714 4.62122 9.61994 4.73516C9.57275 4.8491 9.50357 4.95263 9.41636 5.03983L5.39136 9.06249H16.8757C17.1244 9.06249 17.3628 9.16126 17.5387 9.33708C17.7145 9.51289 17.8132 9.75135 17.8132 9.99999Z"
                  fill="#000"
                ></path>
              </svg>
            </div>
            {currently_previewing?.filter((el1) => !el1.endsWith(".pdf"))
              .length > 1 ? (
              <SlickSlider
                list={currently_previewing
                  ?.filter((el1) => !el1.endsWith(".pdf"))
                  .map((el) => {
                    return (
                      <div className="slider_main">
                        <img src={el} />
                      </div>
                    );
                  })}
              />
            ) : (
              <div className="slider_main">
                <img
                  src={
                    currently_previewing?.filter(
                      (el1) => !el1.endsWith(".pdf")
                    )[0]
                  }
                />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {current_activity_filter.toLowerCase() == "notes" ? (
          <div className="notes_group">
            <textarea
              className="order_input"
              value={curr_notes}
              onChange={({ target }) => {
                setcurr_notes(target.value);
                // setPagevalues(target.value);
                // TxtHistory(target.value);
              }}
              placeholder="Any notes go here... can include missing biomarkers, etc"
            />

            {curr_images_ids?.length > 0 ? (
              <div className="image_group">
                {curr_images_ids?.map((el) => (
                  <div>
                    {curr_images_data[el]?.link ? (
                      <>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.875 2.8125H6.25C5.8356 2.8125 5.43817 2.97712 5.14515 3.27015C4.85212 3.56317 4.6875 3.9606 4.6875 4.375V5.3125H3.125C2.7106 5.3125 2.31317 5.47712 2.02015 5.77015C1.72712 6.06317 1.5625 6.4606 1.5625 6.875V15.625C1.5625 16.0394 1.72712 16.4368 2.02015 16.7299C2.31317 17.0229 2.7106 17.1875 3.125 17.1875H14.375C14.7894 17.1875 15.1868 17.0229 15.4799 16.7299C15.7729 16.4368 15.9375 16.0394 15.9375 15.625V14.0625H16.875C17.2894 14.0625 17.6868 13.8979 17.9799 13.6049C18.2729 13.3118 18.4375 12.9144 18.4375 12.5V4.375C18.4375 3.9606 18.2729 3.56317 17.9799 3.27015C17.6868 2.97712 17.2894 2.8125 16.875 2.8125ZM6.5625 4.6875H16.5625V8.21016L16.3102 7.95781C16.0171 7.66489 15.6198 7.50033 15.2055 7.50033C14.7912 7.50033 14.3938 7.66489 14.1008 7.95781L12.9148 9.14062L10.1672 6.39531C9.87418 6.10239 9.47682 5.93783 9.0625 5.93783C8.64818 5.93783 8.25082 6.10239 7.95781 6.39531L6.5625 7.79063V4.6875ZM14.0625 15.3125H3.4375V7.1875H4.6875V12.5C4.6875 12.9144 4.85212 13.3118 5.14515 13.6049C5.43817 13.8979 5.8356 14.0625 6.25 14.0625H14.0625V15.3125ZM6.5625 12.1875V10.4422L9.0625 7.94219L12.2523 11.132C12.3394 11.2194 12.4429 11.2888 12.5569 11.3361C12.6708 11.3834 12.793 11.4078 12.9164 11.4078C13.0398 11.4078 13.162 11.3834 13.2759 11.3361C13.3899 11.2888 13.4934 11.2194 13.5805 11.132L15.2078 9.50469L16.5625 10.8594V12.1875H6.5625Z"
                            fill="#454545"
                          />
                        </svg>
                        <div className="name">{curr_images_data[el]?.name}</div>
                        <div
                          className="preview"
                          onClick={() =>
                            setcurrently_previewing(
                              curr_images_ids.length > 1
                                ? curr_images_ids.map(
                                    (el) => curr_images_data[el].link
                                  )
                                : [curr_images_data[curr_images_ids[0]].link]
                            )
                          }
                        >
                          Preview
                        </div>
                        <div
                          className="delete"
                          onClick={() => {
                            setcurr_images_data((prev) => ({
                              ...prev,
                              [el]: null,
                            }));
                            setcurr_images_ids((prev) =>
                              prev.filter((el1) => el1 !== el)
                            );
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" fill="white"></rect>
                            <path
                              d="M16.2907 14.961C16.4668 15.1371 16.5658 15.376 16.5658 15.6251C16.5658 15.8741 16.4668 16.113 16.2907 16.2891C16.1146 16.4652 15.8757 16.5642 15.6266 16.5642C15.3776 16.5642 15.1387 16.4652 14.9626 16.2891L10.0024 11.3274L5.04069 16.2876C4.86457 16.4637 4.6257 16.5626 4.37663 16.5626C4.12755 16.5626 3.88868 16.4637 3.71256 16.2876C3.53644 16.1114 3.4375 15.8726 3.4375 15.6235C3.4375 15.3744 3.53644 15.1356 3.71256 14.9594L8.67428 9.99928L3.71413 5.03756C3.53801 4.86144 3.43906 4.62257 3.43906 4.3735C3.43906 4.12443 3.53801 3.88556 3.71413 3.70944C3.89025 3.53332 4.12912 3.43437 4.37819 3.43437C4.62726 3.43437 4.86613 3.53332 5.04225 3.70944L10.0024 8.67116L14.9641 3.70866C15.1402 3.53254 15.3791 3.43359 15.6282 3.43359C15.8773 3.43359 16.1161 3.53254 16.2923 3.70866C16.4684 3.88478 16.5673 4.12365 16.5673 4.37272C16.5673 4.62179 16.4684 4.86066 16.2923 5.03678L11.3305 9.99928L16.2907 14.961Z"
                              fill="#777777"
                            ></path>
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>
                        <svg
                          style={{ transform: "rotate(-60deg)" }}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className="circle_svg_api"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="9"
                            cy="9"
                            r="8"
                            stroke="#777777"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            strokeDashoffset={
                              70 - (curr_images_data[el]?.loading / 100) * 56
                            }
                            strokeDasharray="56"
                          />
                        </svg>

                        <div className="name">{el}</div>

                        <div
                          className="preview"
                          onClick={() =>
                            setcurrently_previewing(
                              curr_images_ids.length > 1
                                ? curr_images_ids.map(
                                    (el) => curr_images_data[el].link
                                  )
                                : [curr_images_data[curr_images_ids[0]].link]
                            )
                          }
                        >
                          Preview
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}

            <div className="notes_flex">
              <label className="order_image" htmlFor={"order_notes"}>
                <input
                  type="file"
                  id={"order_notes"}
                  capture={true}
                  accept="image/*"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={async (e) => {
                    // convert_to_base64(e, el.type);

                    const fileData = await fileToBase64(e);
                    let new_id = "imgid" + new Date().valueOf().toString();
                    let new_image = {
                      [new_id]: {
                        name: fileData.name,
                        base64: fileData.base64,
                        link: "",
                        loading: 0,
                      },
                    };
                    setcurr_images_ids((prev) => [...prev, new_id]);
                    setcurr_images_data((prev) => ({ ...prev, ...new_image }));
                    createfiletolink(
                      testid,
                      fileData,
                      (success) => {
                        if (success?.link) {
                          setcurr_images_data((prev) => ({
                            ...prev,
                            [new_id]: {
                              ...prev[new_id],
                              link: success.link,
                              name: decodeURIComponent(
                                success.link
                                  .split("/")
                                  .slice(-1)[0]
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")
                              ),
                              base64: "",
                            },
                          }));
                        } else {
                          // setcurr_images_data((prev
                        }
                      },
                      (error) => {},
                      (loadingPercentage) => {
                        setcurr_images_data((prev) => ({
                          ...prev,
                          [new_id]: {
                            ...new_image[new_id],
                            loading: loadingPercentage,
                          },
                        }));
                      }
                    );
                  }}
                  multiple={false}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.875 2.8125H6.25C5.8356 2.8125 5.43817 2.97712 5.14515 3.27015C4.85212 3.56317 4.6875 3.9606 4.6875 4.375V5.3125H3.125C2.7106 5.3125 2.31317 5.47712 2.02015 5.77015C1.72712 6.06317 1.5625 6.4606 1.5625 6.875V15.625C1.5625 16.0394 1.72712 16.4368 2.02015 16.7299C2.31317 17.0229 2.7106 17.1875 3.125 17.1875H14.375C14.7894 17.1875 15.1868 17.0229 15.4799 16.7299C15.7729 16.4368 15.9375 16.0394 15.9375 15.625V14.0625H16.875C17.2894 14.0625 17.6868 13.8979 17.9799 13.6049C18.2729 13.3118 18.4375 12.9144 18.4375 12.5V4.375C18.4375 3.9606 18.2729 3.56317 17.9799 3.27015C17.6868 2.97712 17.2894 2.8125 16.875 2.8125ZM6.5625 4.6875H16.5625V8.21016L16.3102 7.95781C16.0171 7.66489 15.6198 7.50033 15.2055 7.50033C14.7912 7.50033 14.3938 7.66489 14.1008 7.95781L12.9148 9.14062L10.1672 6.39531C9.87418 6.10239 9.47682 5.93783 9.0625 5.93783C8.64818 5.93783 8.25082 6.10239 7.95781 6.39531L6.5625 7.79063V4.6875ZM14.0625 15.3125H3.4375V7.1875H4.6875V12.5C4.6875 12.9144 4.85212 13.3118 5.14515 13.6049C5.43817 13.8979 5.8356 14.0625 6.25 14.0625H14.0625V15.3125ZM6.5625 12.1875V10.4422L9.0625 7.94219L12.2523 11.132C12.3394 11.2194 12.4429 11.2888 12.5569 11.3361C12.6708 11.3834 12.793 11.4078 12.9164 11.4078C13.0398 11.4078 13.162 11.3834 13.2759 11.3361C13.3899 11.2888 13.4934 11.2194 13.5805 11.132L15.2078 9.50469L16.5625 10.8594V12.1875H6.5625Z"
                    fill="#454545"
                  />
                </svg>
                <div>Attach...</div>
              </label>
              {/* {!curr_note_edit?.id ? (
                <div
                  className="btn dark_btn"
                  onClick={() => {
                    type == "customer"
                      ? AddCustomerNotes(
                          token,
                          {
                            message: curr_notes,
                            images: curr_images_ids.map(
                              (el) => curr_images_data[el].link
                            ),
                          },
                          order_id,
                          () => {
                            window.location.reload();
                          },
                          () => {}
                        )
                      : AddOrderNotes(
                          token,
                          {
                            message: curr_notes,
                            images: curr_images_ids.map(
                              (el) => curr_images_data[el].link
                            ),
                          },
                          order_id,
                          () => {
                            window.location.reload();
                          },
                          () => {}
                        );
                  }}
                >
                  Add
                </div>
              ) : (
                <>
                  <div
                    className="btn cancel_btn"
                    onClick={() => {
                      setcurr_note_edit({ id: "" });
                      setcurr_images_ids([]);
                      setcurr_images_data({});
                      setcurr_notes("");
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn dark_btn"
                    onClick={() => {
                      type == "customer"
                        ? EditCustomerNotes(
                            token,
                            {
                              noteId: curr_note_edit?.id || "",
                              text: curr_notes,
                              images: curr_images_ids.map(
                                (el) => curr_images_data[el].link
                              ),
                            },
                            order_id,
                            () => {
                              window.location.reload();
                            },
                            () => {}
                          )
                        : EditOrderNotes(
                            token,
                            {
                              message: curr_notes,
                              images: curr_images_ids.map(
                                (el) => curr_images_data[el].link
                              ),
                            },
                            curr_note_edit?.id || "",
                            () => {
                              window.location.reload();
                            },
                            () => {}
                          );
                    }}
                  >
                    Save
                  </div>
                </>
              )} */}
            </div>
            {curr_prescription_ids?.length > 0 ? (
              <div className="image_group b_top">
                {curr_prescription_ids?.map((el) => (
                  <div>
                    {curr_prescription_data[el]?.link ? (
                      <>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.875 2.8125H6.25C5.8356 2.8125 5.43817 2.97712 5.14515 3.27015C4.85212 3.56317 4.6875 3.9606 4.6875 4.375V5.3125H3.125C2.7106 5.3125 2.31317 5.47712 2.02015 5.77015C1.72712 6.06317 1.5625 6.4606 1.5625 6.875V15.625C1.5625 16.0394 1.72712 16.4368 2.02015 16.7299C2.31317 17.0229 2.7106 17.1875 3.125 17.1875H14.375C14.7894 17.1875 15.1868 17.0229 15.4799 16.7299C15.7729 16.4368 15.9375 16.0394 15.9375 15.625V14.0625H16.875C17.2894 14.0625 17.6868 13.8979 17.9799 13.6049C18.2729 13.3118 18.4375 12.9144 18.4375 12.5V4.375C18.4375 3.9606 18.2729 3.56317 17.9799 3.27015C17.6868 2.97712 17.2894 2.8125 16.875 2.8125ZM6.5625 4.6875H16.5625V8.21016L16.3102 7.95781C16.0171 7.66489 15.6198 7.50033 15.2055 7.50033C14.7912 7.50033 14.3938 7.66489 14.1008 7.95781L12.9148 9.14062L10.1672 6.39531C9.87418 6.10239 9.47682 5.93783 9.0625 5.93783C8.64818 5.93783 8.25082 6.10239 7.95781 6.39531L6.5625 7.79063V4.6875ZM14.0625 15.3125H3.4375V7.1875H4.6875V12.5C4.6875 12.9144 4.85212 13.3118 5.14515 13.6049C5.43817 13.8979 5.8356 14.0625 6.25 14.0625H14.0625V15.3125ZM6.5625 12.1875V10.4422L9.0625 7.94219L12.2523 11.132C12.3394 11.2194 12.4429 11.2888 12.5569 11.3361C12.6708 11.3834 12.793 11.4078 12.9164 11.4078C13.0398 11.4078 13.162 11.3834 13.2759 11.3361C13.3899 11.2888 13.4934 11.2194 13.5805 11.132L15.2078 9.50469L16.5625 10.8594V12.1875H6.5625Z"
                            fill="#454545"
                          />
                        </svg>
                        <div className="name">
                          {curr_prescription_data[el]?.name} prescribed
                        </div>
                        <div
                          className="preview"
                          onClick={() =>
                            setcurrently_previewing(
                              curr_prescription_ids.length > 1
                                ? curr_prescription_ids.map(
                                    (el) => curr_prescription_data[el].link
                                  )
                                : [
                                    curr_prescription_data[
                                      curr_prescription_ids[0]
                                    ].link,
                                  ]
                            )
                          }
                        >
                          Preview
                        </div>
                        <div
                          className="delete"
                          onClick={() => {
                            setcurr_prescription_data((prev) => ({
                              ...prev,
                              [el]: null,
                            }));
                            setcurr_prescription_ids((prev) =>
                              prev.filter((el1) => el1 !== el)
                            );
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" fill="white"></rect>
                            <path
                              d="M16.2907 14.961C16.4668 15.1371 16.5658 15.376 16.5658 15.6251C16.5658 15.8741 16.4668 16.113 16.2907 16.2891C16.1146 16.4652 15.8757 16.5642 15.6266 16.5642C15.3776 16.5642 15.1387 16.4652 14.9626 16.2891L10.0024 11.3274L5.04069 16.2876C4.86457 16.4637 4.6257 16.5626 4.37663 16.5626C4.12755 16.5626 3.88868 16.4637 3.71256 16.2876C3.53644 16.1114 3.4375 15.8726 3.4375 15.6235C3.4375 15.3744 3.53644 15.1356 3.71256 14.9594L8.67428 9.99928L3.71413 5.03756C3.53801 4.86144 3.43906 4.62257 3.43906 4.3735C3.43906 4.12443 3.53801 3.88556 3.71413 3.70944C3.89025 3.53332 4.12912 3.43437 4.37819 3.43437C4.62726 3.43437 4.86613 3.53332 5.04225 3.70944L10.0024 8.67116L14.9641 3.70866C15.1402 3.53254 15.3791 3.43359 15.6282 3.43359C15.8773 3.43359 16.1161 3.53254 16.2923 3.70866C16.4684 3.88478 16.5673 4.12365 16.5673 4.37272C16.5673 4.62179 16.4684 4.86066 16.2923 5.03678L11.3305 9.99928L16.2907 14.961Z"
                              fill="#777777"
                            ></path>
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>
                        <svg
                          style={{ transform: "rotate(-60deg)" }}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className="circle_svg_api"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="9"
                            cy="9"
                            r="8"
                            stroke="#777777"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            strokeDashoffset={
                              70 - (curr_images_data[el]?.loading / 100) * 56
                            }
                            strokeDasharray="56"
                          />
                        </svg>

                        <div className="name">{el}</div>

                        <div
                          className="preview"
                          onClick={() =>
                            setcurrently_previewing(
                              curr_images_ids.length > 1
                                ? curr_images_ids.map(
                                    (el) => curr_images_data[el].link
                                  )
                                : [curr_images_data[curr_images_ids[0]].link]
                            )
                          }
                        >
                          Preview
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="notes_flex ">
              <label className="order_image" htmlFor={"prescribed_notes"}>
                <input
                  type="file"
                  id={"prescribed_notes"}
                  capture={true}
                  accept=".pdf,image/*"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={async (e) => {
                    // convert_to_base64(e, el.type);

                    const fileData = await fileToBase64(e);
                    let new_id =
                      "prescribedid" + new Date().valueOf().toString();
                    let new_image = {
                      [new_id]: {
                        name: fileData.name,
                        base64: fileData.base64,
                        link: "",
                        loading: 0,
                      },
                    };
                    setcurr_prescription_ids((prev) => [...prev, new_id]);
                    setcurr_prescription_data((prev) => ({
                      ...prev,
                      ...new_image,
                    }));
                    createfiletolink(
                      testid,
                      fileData,
                      (success) => {
                        if (success?.link) {
                          setcurr_prescription_data((prev) => ({
                            ...prev,
                            [new_id]: {
                              ...prev[new_id],
                              link: success.link,
                              name: decodeURIComponent(
                                success.link
                                  .split("/")
                                  .slice(-1)[0]
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")
                              ),
                              base64: "",
                            },
                          }));
                        } else {
                          // setcurr_images_data((prev
                        }
                      },
                      (error) => {},
                      (loadingPercentage) => {
                        setcurr_prescription_data((prev) => ({
                          ...prev,
                          [new_id]: {
                            ...new_image[new_id],
                            loading: loadingPercentage,
                          },
                        }));
                      }
                    );
                  }}
                  multiple={false}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.875 2.8125H6.25C5.8356 2.8125 5.43817 2.97712 5.14515 3.27015C4.85212 3.56317 4.6875 3.9606 4.6875 4.375V5.3125H3.125C2.7106 5.3125 2.31317 5.47712 2.02015 5.77015C1.72712 6.06317 1.5625 6.4606 1.5625 6.875V15.625C1.5625 16.0394 1.72712 16.4368 2.02015 16.7299C2.31317 17.0229 2.7106 17.1875 3.125 17.1875H14.375C14.7894 17.1875 15.1868 17.0229 15.4799 16.7299C15.7729 16.4368 15.9375 16.0394 15.9375 15.625V14.0625H16.875C17.2894 14.0625 17.6868 13.8979 17.9799 13.6049C18.2729 13.3118 18.4375 12.9144 18.4375 12.5V4.375C18.4375 3.9606 18.2729 3.56317 17.9799 3.27015C17.6868 2.97712 17.2894 2.8125 16.875 2.8125ZM6.5625 4.6875H16.5625V8.21016L16.3102 7.95781C16.0171 7.66489 15.6198 7.50033 15.2055 7.50033C14.7912 7.50033 14.3938 7.66489 14.1008 7.95781L12.9148 9.14062L10.1672 6.39531C9.87418 6.10239 9.47682 5.93783 9.0625 5.93783C8.64818 5.93783 8.25082 6.10239 7.95781 6.39531L6.5625 7.79063V4.6875ZM14.0625 15.3125H3.4375V7.1875H4.6875V12.5C4.6875 12.9144 4.85212 13.3118 5.14515 13.6049C5.43817 13.8979 5.8356 14.0625 6.25 14.0625H14.0625V15.3125ZM6.5625 12.1875V10.4422L9.0625 7.94219L12.2523 11.132C12.3394 11.2194 12.4429 11.2888 12.5569 11.3361C12.6708 11.3834 12.793 11.4078 12.9164 11.4078C13.0398 11.4078 13.162 11.3834 13.2759 11.3361C13.3899 11.2888 13.4934 11.2194 13.5805 11.132L15.2078 9.50469L16.5625 10.8594V12.1875H6.5625Z"
                    fill="#454545"
                  />
                </svg>
                <div>Prescription...</div>
              </label>
              {!curr_note_edit?.id ? (
                <div
                  className="btn dark_btn"
                  onClick={() => {
                    type == "customer"
                      ? AddCustomerNotes(
                          token,
                          {
                            message: curr_notes,
                            images: curr_images_ids.map(
                              (el) => curr_images_data[el].link
                            ),
                            prescription: curr_prescription_ids.map(
                              (el) => curr_prescription_data[el].link
                            ),
                          },
                          order_id,
                          () => {
                            window.location.reload();
                          },
                          () => {}
                        )
                      : AddOrderNotes(
                          token,
                          {
                            message: curr_notes,
                            images: curr_images_ids.map(
                              (el) => curr_images_data[el].link
                            ),
                            prescription: curr_prescription_ids.map(
                              (el) => curr_prescription_data[el].link
                            ),
                          },
                          order_id,
                          () => {
                            window.location.reload();
                          },
                          () => {}
                        );
                  }}
                >
                  Add
                </div>
              ) : (
                <>
                  <div
                    className="btn cancel_btn"
                    onClick={() => {
                      setcurr_note_edit({ id: "" });
                      setcurr_images_ids([]);
                      setcurr_images_data({});
                      setcurr_prescription_ids([]);
                      setcurr_prescription_data({});
                      setcurr_notes("");
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn dark_btn"
                    onClick={() => {
                      type == "customer"
                        ? EditCustomerNotes(
                            token,
                            {
                              noteId: curr_note_edit?.id || "",
                              text: curr_notes,
                              images: curr_images_ids.map(
                                (el) => curr_images_data[el].link
                              ),
                              prescription: curr_prescription_ids.map(
                                (el) => curr_prescription_data[el].link
                              ),
                            },
                            order_id,
                            () => {
                              window.location.reload();
                            },
                            () => {}
                          )
                        : EditOrderNotes(
                            token,
                            {
                              message: curr_notes,
                              images: curr_images_ids.map(
                                (el) => curr_images_data[el].link
                              ),
                              prescription: curr_prescription_ids.map(
                                (el) => curr_prescription_data[el].link
                              ),
                            },
                            curr_note_edit?.id || "",
                            () => {
                              window.location.reload();
                            },
                            () => {}
                          );
                    }}
                  >
                    Save
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {activity
          .filter(({ activity: { type } }) => {
            if (current_activity_filter.toLowerCase() === "notes") {
              return type.toLowerCase() === "note";
            } else if (current_activity_filter.toLowerCase() === "milestones") {
              return type.toLowerCase() === "milestone";
            }
            return true;
          })
          .map((el) => {
            //
            const {
              activity: {
                type = "",
                createdAt,
                createdBy,
                // orderId,
                productName,
                txt,
                prev,
                curr,
                dateTime,
                status,
                text,
                current,
              },
              orderInfo: { orderId },
            } = el;
            if (!type) {
              return "";
            }
            return (
              <div className="activity_item">
                {type.toLowerCase() == "date" ? (
                  <div className="date_change">
                    <div>{dateFormatter(createdAt, "DD MMS")}</div>
                  </div>
                ) : type.toLowerCase() == "initiateorder" ? (
                  <div className="item_flex">
                    <div className="icon">{svg.pluscircle}</div>
                    <div className="txt">
                      Order
                      <span className="highlighted">
                        {orderId ? "AH" + orderId : "NA"}
                      </span>
                      created for
                      <span className="bold">{productName || "NA"}</span>
                    </div>
                    <div className="time">
                      {dateFormatter(createdAt, "hh:mm A")}
                    </div>
                  </div>
                ) : type.toLowerCase() == "milestone" ? (
                  <div className="item_flex" type="milestone">
                    <div className="txt">
                      {svg.star}
                      {txt || text}
                    </div>
                  </div>
                ) : type.toLowerCase() == "statusupdate" ? (
                  <div className="item_flex">
                    <div className="icon">{svg.arrowright}</div>
                    <div className="txt">
                      Status Changed from
                      <span
                        className="status"
                        status={prev?.priority?.toLowerCase()}
                      >
                        {prev?.status || "NA"}
                      </span>
                      to
                      <span
                        className="status"
                        status={curr?.priority?.toLowerCase()}
                      >
                        {curr?.status || "NA"}
                      </span>
                    </div>
                    <div className="time">
                      {dateFormatter(createdAt, "hh:mm A")}
                    </div>
                  </div>
                ) : type.toLowerCase() == "notification" ? (
                  <div className="item_flex">
                    <div className="icon">{svg.chat}</div>
                    <div className="txt">{txt || text}</div>
                    <div className="time">
                      {dateFormatter(createdAt, "hh:mm A")}
                    </div>
                  </div>
                ) : type.toLowerCase() == "note" ? (
                  <div className="item_flex" type="note">
                    <div className="icon">{svg.document}</div>
                    <div className="note_group">
                      {createdBy ? (
                        <div className="createdby">
                          <div>
                            <span className="bold">{createdBy}</span>&nbsp;
                            <span>added a note</span>
                          </div>
                          <div className="time">
                            {dateFormatter(createdAt, "hh:mm A")}
                          </div>
                          {current_activity_filter.toLowerCase() == "notes" ? (
                            <div
                              className="edit"
                              onClick={() => {
                                setcurr_note_edit({ id: el._id });
                                setcurr_notes(
                                  el?.activity?.current?.text || ""
                                );
                                let images = el?.activity?.current?.image?.map(
                                  (el, i) => ({
                                    id: "edit_image_id_" + i,
                                    name: el
                                      .split("/")
                                      .slice(-1)[0]
                                      .split(".")
                                      .slice(0, -1)
                                      .join("."),
                                    link: el,
                                  })
                                );
                                setcurr_images_ids(images.map((el) => el.id));
                                setcurr_images_data(
                                  images.reduce(
                                    (acc, curr) => ({
                                      ...acc,
                                      [curr.id]: {
                                        ...curr,
                                      },
                                    }),
                                    {}
                                  )
                                );
                                let prescription =
                                  el?.activity?.current?.prescription?.map(
                                    (el, i) => ({
                                      id: "edit_prescription_id_" + i,
                                      name: el
                                        .split("/")
                                        .slice(-1)[0]
                                        .split(".")
                                        .slice(0, -1)
                                        .join("."),
                                      link: el,
                                    })
                                  );
                                setcurr_prescription_ids(
                                  prescription.map((el) => el.id)
                                );
                                setcurr_prescription_data(
                                  prescription.reduce(
                                    (acc, curr) => ({
                                      ...acc,
                                      [curr.id]: {
                                        ...curr,
                                      },
                                    }),
                                    {}
                                  )
                                );
                              }}
                            >
                              Edit
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        "NA"
                      )}
                      {current?.text ? (
                        <div className="txt">{current?.text}</div>
                      ) : (
                        ""
                      )}
                      {current?.image ? (
                        <>
                          {current.image.map((el) => (
                            <div
                              className="txt image_group"
                              onClick={() => {
                                setcurrently_previewing(current.image);
                              }}
                            >
                              <div
                                className="image"
                                style={{ backgroundImage: "url(" + el + ")" }}
                              ></div>
                              <div>
                                {decodeURIComponent(el.split("/").slice(-1))}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      {current?.prescription ? (
                        <>
                          {current.prescription.map((el) => (
                            <div
                              className="txt image_group"
                              onClick={() => {
                                if (
                                  documentTypes.some((type) =>
                                    el.endsWith(type)
                                  )
                                ) {
                                  window.open(el, "_blank");
                                } else {
                                  setcurrently_previewing(current.prescription);
                                }
                              }}
                            >
                              <div
                                className={
                                  "image" +
                                  (documentTypes.some((type) =>
                                    el.endsWith(type)
                                  )
                                    ? " prescription"
                                    : "")
                                }
                                style={{
                                  backgroundImage:
                                    `url("https://res.cloudinary.com/doht5pjdp/image/fetch/f_auto,w_500,c_limit/` +
                                    el +
                                    `")`,
                                }}
                              ></div>
                              <div>
                                Prescription{" "}
                                {decodeURIComponent(el.split("/").slice(-1))}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : type.toLowerCase() == "scheduleupdate" ? (
                  <div className="item_flex">
                    <div className="icon">{svg.calendar}</div>
                    <div className="txt">
                      <span className="highlighted">{status}</span>
                      for
                      <span className="bold">
                        {dateTime
                          ? dateFormatter(dateTime, "hh:mm A on DD/MM/YYYY")
                          : "NA"}
                      </span>
                    </div>
                  </div>
                ) : type.toLowerCase() == "assignedto" ? (
                  <div className="item_flex">
                    <div className="icon">{svg.chat}</div>
                    <div className="txt">
                      Assigned to
                      <span className="bold">{txt || text}</span>
                    </div>
                    <div className="time">
                      {dateFormatter(createdAt, "hh:mm A")}
                    </div>
                  </div>
                ) : (
                  type
                )}
                {/* {createdAt.toString()} */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ActivityTracker;
