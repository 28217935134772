import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { svg } from "../../../utils/svg";
import { useSelector } from "react-redux";

const DashboardNavigation = () => {
  const navigate = useNavigate();
  const {
    user: { name },
  } = useSelector((state) => state.appState);
  const store = useSelector((state) => state);
  const { pathname } = useMatch("*");
  const nav = [
    {
      name: "Orders",
      path: "orders",
      goTo: "orders/all",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6667 7.33333H3.33333M12.6667 7.33333C13.0203 7.33333 13.3594 7.47381 13.6095 7.72386C13.8595 7.97391 14 8.31304 14 8.66667V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V8.66667C2 8.31304 2.14048 7.97391 2.39052 7.72386C2.64057 7.47381 2.97971 7.33333 3.33333 7.33333M12.6667 7.33333V6C12.6667 5.64638 12.5262 5.30724 12.2761 5.05719C12.0261 4.80714 11.687 4.66667 11.3333 4.66667M3.33333 7.33333V6C3.33333 5.64638 3.47381 5.30724 3.72386 5.05719C3.97391 4.80714 4.31304 4.66667 4.66667 4.66667M11.3333 4.66667V3.33333C11.3333 2.97971 11.1929 2.64057 10.9428 2.39052C10.6928 2.14048 10.3536 2 10 2H6C5.64638 2 5.30724 2.14048 5.05719 2.39052C4.80714 2.64057 4.66667 2.97971 4.66667 3.33333V4.66667M11.3333 4.66667H4.66667"
            stroke="var(--svg_color,#000)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Customers",
      path: "customers",
      goTo: "customers/all",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 3.62833C10.4479 3.12059 11.0397 2.76126 11.6968 2.59816C12.354 2.43507 13.0451 2.47594 13.6785 2.71533C14.3118 2.95473 14.8572 3.38131 15.2421 3.9383C15.627 4.49529 15.8332 5.15629 15.8332 5.83333C15.8332 6.51038 15.627 7.17138 15.2421 7.72837C14.8572 8.28536 14.3118 8.71193 13.6785 8.95133C13.0451 9.19073 12.354 9.2316 11.6968 9.06851C11.0397 8.90541 10.4479 8.54608 10 8.03833M12.5 17.5H2.5V16.6667C2.5 15.3406 3.02678 14.0688 3.96447 13.1311C4.90215 12.1935 6.17392 11.6667 7.5 11.6667C8.82608 11.6667 10.0979 12.1935 11.0355 13.1311C11.9732 14.0688 12.5 15.3406 12.5 16.6667V17.5ZM12.5 17.5H17.5V16.6667C17.5001 15.7889 17.2692 14.9266 16.8304 14.1664C16.3916 13.4062 15.7604 12.7749 15.0003 12.336C14.2402 11.8971 13.3779 11.666 12.5002 11.666C11.6224 11.6659 10.7601 11.897 10 12.3358M10.8333 5.83333C10.8333 6.71739 10.4821 7.56524 9.85702 8.19036C9.2319 8.81548 8.38405 9.16667 7.5 9.16667C6.61594 9.16667 5.7681 8.81548 5.14298 8.19036C4.51786 7.56524 4.16667 6.71739 4.16667 5.83333C4.16667 4.94928 4.51786 4.10143 5.14298 3.47631C5.7681 2.85119 6.61594 2.5 7.5 2.5C8.38405 2.5 9.2319 2.85119 9.85702 3.47631C10.4821 4.10143 10.8333 4.94928 10.8333 5.83333Z"
            stroke="var(--svg_color,#000)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Discount",
      path: "discounts",
      goTo: "discounts",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9.20709 2.43915C9.45709 1.67165 10.5429 1.67165 10.7921 2.43915L12.0579 6.33415C12.1124 6.50122 12.2183 6.6468 12.3605 6.75007C12.5027 6.85334 12.6739 6.90902 12.8496 6.90915H16.9454C17.7529 6.90915 18.0879 7.94248 17.4354 8.41748L14.1221 10.8242C13.9798 10.9276 13.8738 11.0735 13.8195 11.2408C13.7652 11.4082 13.7652 11.5885 13.8196 11.7558L15.0846 15.6508C15.3346 16.4192 14.4554 17.0575 13.8029 16.5825L10.4896 14.1758C10.3472 14.0723 10.1756 14.0165 9.99959 14.0165C9.82353 14.0165 9.652 14.0723 9.50959 14.1758L6.19626 16.5825C5.54376 17.0575 4.66459 16.4183 4.91459 15.6508L6.17959 11.7558C6.23399 11.5885 6.23402 11.4082 6.17968 11.2408C6.12534 11.0735 6.01942 10.9276 5.87709 10.8242L2.56376 8.41748C1.91042 7.94248 2.24709 6.90915 3.05376 6.90915H7.14876C7.32463 6.9092 7.49601 6.8536 7.63836 6.75032C7.78071 6.64703 7.88674 6.50136 7.94126 6.33415L9.20709 2.43915Z"
            stroke="var(--svg_color,#000)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Products",
      path: "products",
      goTo: "products",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.83333 5.83333H5.84167M5.83333 2.5H10C10.4267 2.5 10.8533 2.6625 11.1783 2.98833L17.0117 8.82167C17.3241 9.13421 17.4996 9.55806 17.4996 10C17.4996 10.4419 17.3241 10.8658 17.0117 11.1783L11.1783 17.0117C10.8658 17.3241 10.4419 17.4996 10 17.4996C9.55806 17.4996 9.13421 17.3241 8.82167 17.0117L2.98833 11.1783C2.83334 11.0238 2.71041 10.8401 2.6266 10.6379C2.5428 10.4357 2.49977 10.2189 2.5 10V5.83333C2.5 4.94928 2.85119 4.10143 3.47631 3.47631C4.10143 2.85119 4.94928 2.5 5.83333 2.5Z"
            stroke="var(--svg_color,#000)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    store?.appState?.user?.type == "Admin"
      ? {
          name: "Reports",
          path: "report",
          goTo: "report",
          svg: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.332 6.66659V13.3333M9.9987 9.16659V13.3333M6.66536 11.6666V13.3333M4.9987 16.6666H14.9987C15.4407 16.6666 15.8646 16.491 16.1772 16.1784C16.4898 15.8659 16.6654 15.4419 16.6654 14.9999V4.99992C16.6654 4.55789 16.4898 4.13397 16.1772 3.82141C15.8646 3.50885 15.4407 3.33325 14.9987 3.33325H4.9987C4.55667 3.33325 4.13275 3.50885 3.82019 3.82141C3.50763 4.13397 3.33203 4.55789 3.33203 4.99992V14.9999C3.33203 15.4419 3.50763 15.8659 3.82019 16.1784C4.13275 16.491 4.55667 16.6666 4.9987 16.6666Z"
                stroke="var(--svg_color,#000)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
        }
      : "",
    // {
    //   name: "Discounts",
    //   path: "discounts",
    // },
    // {
    //   name: "Users",
    //   path: "users",
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="16"
    //       height="16"
    //       viewBox="0 0 16 16"
    //       fill="none"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         clip-rule="evenodd"
    //         d="M8.00039 7.1999C8.63691 7.1999 9.24736 6.94705 9.69745 6.49696C10.1475 6.04687 10.4004 5.43642 10.4004 4.7999C10.4004 4.16338 10.1475 3.55293 9.69745 3.10285C9.24736 2.65276 8.63691 2.3999 8.00039 2.3999C7.36387 2.3999 6.75342 2.65276 6.30333 3.10285C5.85325 3.55293 5.60039 4.16338 5.60039 4.7999C5.60039 5.43642 5.85325 6.04687 6.30333 6.49696C6.75342 6.94705 7.36387 7.1999 8.00039 7.1999ZM2.40039 14.3999C2.40039 13.6645 2.54524 12.9363 2.82667 12.2569C3.10809 11.5775 3.52058 10.9601 4.04059 10.4401C4.5606 9.9201 5.17794 9.5076 5.85736 9.22618C6.53679 8.94475 7.26499 8.7999 8.00039 8.7999C8.73579 8.7999 9.46399 8.94475 10.1434 9.22618C10.8228 9.5076 11.4402 9.9201 11.9602 10.4401C12.4802 10.9601 12.8927 11.5775 13.1741 12.2569C13.4555 12.9363 13.6004 13.6645 13.6004 14.3999H2.40039Z"
    //         fill="var(--svg_color,#000)"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: "Invoices",
    //   path: "invoices",
    // },
  ];
  return (
    <div className="dashboard_nav">
      {svg.logo("logo")}
      {nav.map(({ name, path, svg, goTo }) => (
        <>
          <div
            {...{
              is_active: pathname.split("/")[2] == path ? "true" : "",
              className: "nav_items",
              onClick: () => navigate("./" + goTo),
            }}
          >
            {svg || ""}
            {name}
          </div>
        </>
      ))}
      <div
        className="profile"
        onClick={() => {
          localStorage.clear();
          // navigate("/");
          window.location.reload();
        }}
      >
        {/* <div className="avatar"></div> */}
        <div className="name">{name || "N/A"}</div>
        <div className="logout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 512.016 512"
            space="preserve"
          >
            <g>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m496 240.007812h-202.667969c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h202.667969c8.832031 0 16 7.167969 16 16 0 8.832032-7.167969 16-16 16zm0 0"
                fill="#000000"
                data-original="#000000"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m416 320.007812c-4.097656 0-8.191406-1.558593-11.308594-4.691406-6.25-6.253906-6.25-16.386718 0-22.636718l68.695313-68.691407-68.695313-68.695312c-6.25-6.25-6.25-16.382813 0-22.632813 6.253906-6.253906 16.386719-6.253906 22.636719 0l80 80c6.25 6.25 6.25 16.382813 0 22.632813l-80 80c-3.136719 3.15625-7.230469 4.714843-11.328125 4.714843zm0 0"
                fill="#000000"
                data-original="#000000"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m170.667969 512.007812c-4.566407 0-8.898438-.640624-13.226563-1.984374l-128.386718-42.773438c-17.46875-6.101562-29.054688-22.378906-29.054688-40.574219v-384c0-23.53125 19.136719-42.6679685 42.667969-42.6679685 4.5625 0 8.894531.6406255 13.226562 1.9843755l128.382813 42.773437c17.472656 6.101563 29.054687 22.378906 29.054687 40.574219v384c0 23.53125-19.132812 42.667968-42.664062 42.667968zm-128-480c-5.867188 0-10.667969 4.800782-10.667969 10.667969v384c0 4.542969 3.050781 8.765625 7.402344 10.28125l127.785156 42.582031c.917969.296876 2.113281.46875 3.480469.46875 5.867187 0 10.664062-4.800781 10.664062-10.667968v-384c0-4.542969-3.050781-8.765625-7.402343-10.28125l-127.785157-42.582032c-.917969-.296874-2.113281-.46875-3.476562-.46875zm0 0"
                fill="#000000"
                data-original="#000000"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m325.332031 170.675781c-8.832031 0-16-7.167969-16-16v-96c0-14.699219-11.964843-26.667969-26.664062-26.667969h-240c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-15.9999995 16-15.9999995h240c32.363281 0 58.664062 26.3046875 58.664062 58.6679685v96c0 8.832031-7.167969 16-16 16zm0 0"
                fill="#000000"
                data-original="#000000"
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m282.667969 448.007812h-85.335938c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h85.335938c14.699219 0 26.664062-11.96875 26.664062-26.667968v-96c0-8.832032 7.167969-16 16-16s16 7.167968 16 16v96c0 32.363281-26.300781 58.667968-58.664062 58.667968zm0 0"
                fill="#000000"
                data-original="#000000"
              ></path>
            </g>
          </svg>
          {/* Sign out */}
        </div>
        {/* <div className="arrow">{svg.arrow()}</div> */}
      </div>
    </div>
  );
};

export default DashboardNavigation;
