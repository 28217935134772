export const LocalToken = {
  set: (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    return true;
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem("token"));
  },
};
export const LocalData = {
  set: (token) => {
    localStorage.setItem("appdata-admin", JSON.stringify(token));
    return true;
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem("appdata-admin"));
  },
};
