import React from "react";

const CustInputMuiClone = ({ attr, data, unique_id }) => {
  const curr_id = unique_id;
  const current_inp_obj = {
    readonly: false,
  };
  return (
    <>
      <label
        className={
          "cust_input with_prefix" +
          (attr?.class ? ` ${attr.class}` : "") +
          (attr?.error ? " err_active" : "")
        }
        htmlFor={attr?.readonly && false ? "" : curr_id}
        onClick={attr?.event?.click || null}
      >
        <div className="input_group_main">
          {data?.prefix ? (
            <div className="input_prefix">{data.prefix}</div>
          ) : (
            ""
          )}
          <div className={"input_group2 "}>
            <input
              type={attr?.type || "text"}
              value={
                attr?.value
                  ? attr?.value
                  : Number(attr?.value) == 0
                  ? attr?.value
                  : ""
              }
              // autoComplete="false"
              onChange={attr.event.change}
              onKeyDown={attr.event.onkeydown}
              id={curr_id}
              placeholder={data?.placeholder}
              inputMode={attr?.inputMode || "email"}
              // type="password"
              className={data.title ? " active_pad" : " unactive_pad"}
              readOnly={attr.readonly || current_inp_obj.readonly}
              {...{
                ...(attr?.dataset?.id ? { list: attr?.dataset?.id } : {}),
              }}
            />
            {data?.title ? (
              <div
                className="cust_input_title"
                active={attr.value ? "true" : ""}
              >
                {/* <div className="hidden_prefix">{data?.prefix || ""}</div> */}
                <div>{data.title}</div>
              </div>
            ) : (
              ""
            )}
          </div>
          {data?.suffix ? (
            <div className="input_suffix">{data.suffix}</div>
          ) : (
            ""
          )}
        </div>

        {attr?.error_msg ? (
          <div className="input_error">
            <div>{attr.error_msg}</div>
          </div>
        ) : (
          ""
        )}
        {attr?.dataset?.list ? (
          <datalist id={attr?.dataset?.id}>
            {attr?.dataset?.list?.map((el) => (
              <option>{el}</option>
            ))}
          </datalist>
        ) : (
          ""
        )}
      </label>
    </>
  );
};

export default CustInputMuiClone;
