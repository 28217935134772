import React, { useState } from "react";

export const MultiSelectDropDown = ({ inp_obj, custclass }) => {
  const [DropOpen, setDropOpen] = useState(false);
  window.addEventListener("click", function () {
    setDropOpen(false);
  });
  //
  return (
    <>
      <div
        className={
          "cust_multi_select_drop_down " + (custclass ? custclass : "")
        }
      >
        <div
          className="inner_cust_multi_select_drop_down"
          onClick={(e) => {
            setDropOpen((prev) => !prev);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="inner_cust_simple_drop_down_title">
            {inp_obj.value.length <= 0 || inp_obj.value == ""
              ? "All"
              : inp_obj.value[0] == "All"
              ? inp_obj.value
              : inp_obj.value.map((el2) => {
                  return (
                    <div className="inner_cust_simple_drop_down_title_inner">
                      {
                        inp_obj?.options?.filter((el) => el.value == el2)[0]
                          ?.label
                      }
                      {el2}
                    </div>
                  );
                })}
          </div>
          <div
            className={
              "inner_cust_simple_drop_down_icons " +
              (DropOpen ? " inner_cust_simple_drop_down_icons_active" : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M5.33203 7.5L10.332 12.5L15.332 7.5"
                stroke="#777777"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {DropOpen && (
          <div className="inner_cust_simple_drop_down_list">
            {inp_obj.drop_list}
          </div>
        )}
      </div>
    </>
  );
};
