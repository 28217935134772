import React, { useEffect, useState } from "react";

const CustTable = ({ data, header, nosrnogap = false }) => {
  const [columsShort, setcolumsShort] = useState([]);
  const [columns, setcolumns] = useState([]);

  useEffect(() => {
    let t_columsShort = [];
    let t_columns = {};
    [...header].map((el) => {
      t_columsShort.push(el.id);
      t_columns[el.id] = el.width;
    });
    setcolumns(t_columns);
    setcolumsShort(t_columsShort);
  }, [header]);

  if (data?.length == 0) {
    return <div style={{ textAlign: "center" }}>No Data</div>;
  }
  return (
    <div className="custtable">
      <div className="header">
        {nosrnogap ? "" : <div className="srno"></div>}
        {header?.map((el, i) => (
          <div key={i} style={{ "--width": columns[el.id] }}>
            {el.title}
          </div>
        ))}
      </div>
      <div className="body">
        {data?.map((el, i) => (
          <div
            className="row"
            key={i}
            onClick={el?.rowClick ? el.rowClick : ""}
          >
            {nosrnogap ? (
              ""
            ) : (
              <div className="srno">{el["nosrno"] ? "" : i + 1}</div>
            )}
            {columsShort.map((el1, i1) => (
              <div key={i1} style={{ "--width": columns[el1] }}>
                {el[el1] || "-"}
              </div>
            ))}
          </div>
        ))}
        {/* {data.length == 0 ? "No data" : ""}
        {data.length} */}
      </div>
    </div>
  );
};

export default CustTable;
