import { LocalData } from "../services/localStorage";
import { discounts } from "../utils/utils";

const default_data = {
  products: {},
  user: {},
  flags: {
    token_validated: false,
  },
  report: {},
};
export const data = {
  ...default_data,
};
const AppState = (state = data, { type, subType, payload }) => {
  const setLocalData = (obj) => {
    LocalData.set({
      user: state.user,
      ...obj,
    });
  };

  let localData = {};
  if (!state.app_update) {
    localData = LocalData.get();
    state = {
      ...state,
      ...localData,
      app_update: true,
    };
  }

  switch (type) {
    case "setAppState":
      switch (subType) {
        case "products":
          return {
            ...state,
            ...payload,
            api: { ...state.api, ...payload?.api },
          };

        case "user":
          setLocalData({ user: payload });
          return {
            ...state,
            user: payload,
            flags: {
              ...state.flags,
              token_validated: true,
            },
          };
        case "employee":
          return {
            ...state,
            employee: [...payload],
          };
        case "allorders":
          return {
            ...state,
            allorders: [...payload],
          };
        case "allordersfilters":
          return {
            ...state,
            allordersfilters: { ...payload },
          };
        case "allcustomers":
          return {
            ...state,
            allcustomers: [...payload],
          };
        case "report":
          return {
            ...state,
            labData: { ...payload },
          };

        default:
          return state;
      }
    case "UpdateAppStateFromLocalStorage":
      //
      localData = LocalData.get();
      return { ...state, ...localData, app_update: true };
    default:
      return state;
  }
};
export default AppState;
